import React, { useEffect } from 'react'

import { 
    HomeOutlined,
    BarcodeOutlined,
    // FileTextOutlined,
    // BoxPlotOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router';

// Store
// import { useSelector } from 'react-redux'

import './Masters.scss'

function Masters({ isMobile, exposeSubMenu, setMainLoading }: any) {
    // const { token } = useSelector((state: any) => state)
    const history = useHistory()

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Resumen', icon: <HomeOutlined /> },
                { key: 'products', name: 'Productos', icon: <BarcodeOutlined /> },
                { key: 'collaborators', name: 'Colaboradores', icon: <UserOutlined /> },
                // { key: 'categories', name: 'Categorìas', icon: <BoxPlotOutlined /> },
                // { key: 'documents', name: 'Documentos', icon: <FileTextOutlined /> },
            ])
        }
    }, [exposeSubMenu])

    useEffect(() => {
        setMainLoading(false)
    }, [setMainLoading])

    return (
        <div className="App">
            <div className="Masters">
                <div className="Wrapper">
                    <Result
                        status="404"
                        title="Mantenedor de Sistema"
                        subTitle={<>
                        En esta sección podrás configurar los elementos básicos del sistema, como el catálogo de productos y los documentos transaccionales disponibles para operar.
                        </>}
                        extra={<>
                            <Button size='large' type="primary" onClick={() => history.push('/masters/products')}>Productos</Button>
                            <Button size='large' type="primary" onClick={() => history.push('/masters/collaborators')}>Colaboradores</Button>
                            {/* <Button size='large' type="primary" onClick={() => history.push('/masters/categories')}>Categorìas</Button>
                            <Button size='large' type="primary" onClick={() => history.push('/masters/documents')}>Documentos</Button> */}
                        </>}
                    />
                </div>
            </div>
        </div>
    )
}

export default Masters