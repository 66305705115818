import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { 
    HomeOutlined,
    RightOutlined,
    QuestionCircleOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    MoneyCollectOutlined
} from '@ant-design/icons';

import { Button, Empty, Modal, Popconfirm, Space, Table, message } from 'antd';
import { useHistory } from 'react-router';
import { useApi } from '../../Utils';
import Column from 'antd/lib/table/Column';

import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

// @ts-ignore
import ChanelFont from '../../Assets/Fonts/ABChanel-PB-SemiBold-S.ttf'
import axios from 'axios';
import { pad } from '../Accounting/ReportCatalogProducts';
import { useSelector } from 'react-redux';

interface OrderRowItem {
    id: number
    paOrderId: number
    orderId: string
    internalCode: string
    productId: number
    quantity: number
    netPrice: number
    created_at: string
    updated_at?: string
}

interface OrderRow {
    id: number
    orderId: string
    date: string
    storeId: number
    extras: string
    paymentPeriod: number
    created_at: string
    updated_at?: string
    dispatchCarrierName: string
    dispatchCarrierCode: string
    lastState: string | null
    items: OrderRowItem[]
}

const ButtonAction = ({ cta, record, setMainLoading }: { cta: string | undefined, record: OrderRow, setMainLoading: Dispatch<SetStateAction<boolean>> }) => {
    const history = useHistory()

    const nextState = new Map([
        ['Liberar', 'EnPreparacion'],
        ['Preparar', null],
        ['Despachar', 'Despachado'],
        ['Despachado', null],
        ['Anulado', null]
    ])

    const { fetch, data, errors, loading } = useApi<any>('post', `v3/sync/state/${record.orderId}/${nextState.get(cta ?? 'Liberar')}`, {
        onRequest: true,
        requiredToken: true
    })

    useEffect(() => {
        console.log(data)
    }, [data])

    useEffect(() => {
        if (errors?.length > 0) {
            for (const error of errors) {
                message.error(`Error: ${error.statusMessage}`)
            }
        }
    }, [errors])

    return <Popconfirm
        disabled={['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`)}
        title={`¿Estás seguro de ${cta?.toLocaleLowerCase()} la orden ${record.orderId}?`}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        placement='left'
        onConfirm={() => {
            const _next = nextState.get(cta ?? 'Liberar')

            if (_next) {
                fetch()
            } else {
                setMainLoading(true)
                history.push(`/orders/${record.orderId}`)
            }
        }}
        onCancel={() => {
            //
        }}
        okType={'danger'}
        okText='Si'
        cancelText='No'
    >
        <Button
            size='small'
            style={{
                minWidth: 128,
                textAlign: 'left'
            }}
            type={!['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`) ? 'primary' : undefined}
            icon={<RightOutlined />}
            onClick={() => {
                if (['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`)) {
                    
                }
            }}
            loading={loading}
        >
            {cta}
        </Button>
    </Popconfirm>
}

function Orders({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { data, loading } = useApi<any>('get', 'v3/sync/orders')
    const [records, setRecords] = React.useState<OrderRow[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('')
    const { permissions } = useSelector((state: any) => state)
    // const { token } = useSelector((state: any) => state)

    useEffect(() => {
        if (!isModalOpen) {
            setMessage('')
        }
    }, [isModalOpen])

    useEffect(() => {
        if (data?.status === 'success') {
            setRecords(data.orders)
            // setRecords(data.orders)
            // console.log(data.orders)
        }
    }, [data])

    useEffect(() => {
        if (!!exposeSubMenu) {
            const _submenu = [
                { key: 'index', name: 'Papel de Trabajo', icon: <HomeOutlined /> },
            ]

            if (permissions.includes('FacturacionMensual')) {
                _submenu.push({ key: 'emission', name: 'Facturación mensual', icon: <MoneyCollectOutlined /> },)
            }

            exposeSubMenu(_submenu)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exposeSubMenu])

    useEffect(() => {
        setMainLoading(false)
    }, [setMainLoading])

    return (
        <div className="App">
            <div className="Orders">
                <div className="Wrapper">
                    <Table
                        dataSource={records}
                        loading={loading}
                        pagination={false}
                        className='TableListAll'
                        size='small'
                        locale={{
                            emptyText: (
                                <Empty
                                    style={{ marginTop: '32px', marginBottom: '48px' }}
                                    description='No se han encontrado órdenes con los criterios de bùsqueda.'
                                >
                                </Empty>
                            )
                        }}
                        footer={() => <>Total de órdenes {records.length}</>}
                    >
                        <Column
                            title='Número'
                            dataIndex='orderId'
                            key='orderId'
                            sortDirections={['descend', 'ascend']}
                            sorter={(a: any, b: any) => a.orderId.localeCompare(b.orderId)}
                        />
                        <Column
                            title='Fecha'
                            dataIndex='date'
                            key='date'
                            sortDirections={['descend', 'ascend']}
                            sorter={(a: any, b: any) => a.date.localeCompare(b.date)}
                        />
                        <Column
                            title='Estado'
                            dataIndex='lastState'
                            key='lastState'
                            filters={[
                                { text: 'Por liberar', value: '' },
                                { text: 'En preparación', value: 'EnPreparacion' },
                                { text: 'Anulado', value: 'PickingParcialAnulado' },
                                { text: 'Pickeado', value: 'Picking' },
                                { text: 'Despachado', value: 'Despachado' },
                            ]}
                            defaultFilteredValue={['', 'EnPreparacion', 'Picking']}
                            onFilter={(value, record: any) => {
                                if (value === '') {
                                    return record.lastState === null
                                }
                                if (value === 'Anulado') {
                                    return record.lastState === 'PickingParcialAnulado'
                                }
                                if (value === 'Picking') {
                                    return record.lastState === 'PickingParcialCompletado' || record.lastState === 'PickingCompleto'
                                }

                                return record.lastState?.indexOf(value) > -1
                            }}
                            render={(value) => {
                                switch (value) {
                                    case 'EnPreparacion':
                                        return <>En preparación</>
                                    case 'PickingParcialCompletado':
                                    case 'PickingCompleto':
                                            return <>Pickeado {value === 'PickingParcialCompletado' && <>(parcial)</>}</>
                                    case 'PickingParcialAnulado':
                                        return <>Anulado</>
                                    case 'Despachado':
                                        return <>Despachado</>
                                    default:
                                        return <>Por liberar</>
                                }
                            }}
                        />
                        <Column
                            title='Referencias'
                            key='products'
                            render={(value, record: OrderRow) => {
                                return <>{record.items.length}</>
                            }}
                        />
                        <Column
                            title='Unidades'
                            key='units'
                            render={(value, record: OrderRow) => {
                                return <>{record.items.reduce((acc, i) => {
                                    acc += i.quantity
                                    return acc
                                }, 0)}</>
                            }}
                        />
                        <Column
                            title='Transportista'
                            dataIndex='dispatchCarrierName'
                            key='dispatchCarrierName'
                            sortDirections={['descend', 'ascend']}
                            sorter={(a: any, b: any) => a.date.localeCompare(b.date)}
                            render={(value, record: any) => {
                                const dictionary = new Map([
                                    ['NXD', '99minutos'],
                                    ['Entrega estándar', 'Starken']
                                ])
                                // (${record.dispatchCarrierCode})
                                if (dictionary.has(value)) {
                                    return `${dictionary.get(value)}`
                                }
                                return `${value}`
                            }}
                        />
                        <Column
                            title='Acciones'
                            key='action'
                            render={(text, record: OrderRow) => {
                                const actions = new Map([
                                    [null, 'Liberar'],
                                    ['EnPreparacion', 'Preparar'],
                                    ['PickingParcialCompletado', 'Despachar'],
                                    ['PickingCompleto', 'Despachar'],
                                    ['PickingParcialAnulado', 'Anulado'],
                                    ['Despachado', 'Despachado'],
                                ])
                                const cta = actions.get(record.lastState)

                                return <Space size='small'>
                                    {/* Action Button */}
                                    <ButtonAction
                                        cta={cta}
                                        record={record}
                                        key={`ButtonAction${record.id}`}
                                        setMainLoading={setMainLoading} />
                                    {/* Excel eOC */}
                                    <ButtonExcel order={record} />
                                    {/* Descarga PDF */}
                                    {record.extras !== null && <Button
                                        size='small'
                                        type={undefined}
                                        onClick={() => {
                                            setMessage(record.extras)
                                            setIsModalOpen(true)
                                        }}
                                        danger
                                    >
                                        <FilePdfOutlined />PDF
                                    </Button>}
                                </Space>
                            }}
                        />
                    </Table>
                    <Modal
                        title="Tarjeta en PDF"
                        style={{
                            minWidth: 890,
                            minHeight: 600,
                        }}
                        visible={isModalOpen}
                        onOk={() => setIsModalOpen(false)}
                        onCancel={() => setIsModalOpen(false)}
                        okText="OK"
                        cancelText="Cancelar"
                    >
                        {message && <PDFViewer style={{
                            width: '100%',
                            height: '100%',
                            minWidth: 800,
                            minHeight: 600,
                        }}>
                            <PDFExport message={message} />
                        </PDFViewer>}
                    </Modal>
                </div>
            </div>
        </div>
    )
}

const ButtonExcel = ({ order }: { order: OrderRow }) => {
    const [loading, setLoading] = useState(false)
    const { token } = useSelector((state: any) => state)

    const downloadExcel = async () => {
        if (!loading) {
            setLoading(true)
            axios({
                method: 'get',
                url: `https://app.bodegaenlinea.cl/api/v3/sync/order/${order.orderId}/xlsx`,
                responseType: 'blob',
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
                // @ts-ignore
                if (!window.navigator.msSaveOrOpenBlob) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', stringDate + ' eOrder ' + order.orderId + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                } else {
                    // @ts-ignore
                    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' eOrder ' + order.orderId + '.xlsx');
                }
                setLoading(false)
            }).catch((err) => {
                alert(err)
                console.log(err)
                setLoading(false)
            })
        }
    }

    return <Button size='small' type={undefined} onClick={downloadExcel} loading={loading} icon={<FileExcelOutlined />}>
        Excel
    </Button>
} 

Font.register({ family: 'ABChanel PB SemiBold S', src: ChanelFont })
Font.registerHyphenationCallback(word => [word])

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFF',
        fontFamily: 'ABChanel PB SemiBold S',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    section: {
        margin: 10,
        marginHorizontal: 20,
        padding: 10,
        flexGrow: 1,
        textAlign: 'center',
        textOverflow: 'ellipsis',
    }
});


/* A4 210, 297 */ 
/* A4 595.28, 841.89 */ 
/* La tasa es MM / 0,352775165 */ 

/* Tarjeta es 90, 125 */ 
/* Tarjeta es 254, 355.6 */ 
/* La tasa es MM / 0,352775165 */ 

const K = 0.352775165

// Create Document Component
const PDFExport = ({ message }: { message:string }) => (
    <Document>

        <Page size={{
            width: 90 / K,
            height: 125 / K
        }} style={styles.page} orientation='landscape'>
            <View style={styles.section}>
                {/* style={styles.text} wrap={true} */}
                <Text>{message}</Text>
            </View>
        </Page>
    </Document>
);

export default Orders