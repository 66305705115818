import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import {
    FileExcelFilled,
    RightOutlined,
    QuestionCircleOutlined,
    FileTwoTone
} from '@ant-design/icons';

import { Button, Empty, Modal, Popconfirm, Select, Space, Table, message } from 'antd';
import { useApi, useApiAR } from '../../Utils';
import Column from 'antd/lib/table/Column';

import { useSelector } from 'react-redux';
import calculateMenu from './calculateMenu';
import { UploadFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import Pusher from 'pusher-js';
import { IPusherMessage, IPusherMessageChunk } from '../types';

interface OrderRow {
    erpBodega: string
    erpBodega2?: string
    erpCliente: string
    erpCorrelativo: number
    erpEmpresa: string
    erpFecha: string
    erpFechaOC?: string
    erpId: number
    erpNumero: string
    erpNumeroOC?: string
    erpProcesado: boolean
    erpRazonSocial: string
    erpTicket?: string
    erpTipoDocto: string
    erpVendedor: string
    erpEDL: boolean
    erpAprobacion: boolean
    id: number
    jobName: string
    job?: {
        id: number
        name: string
        action: string
        enabled: boolean
        created_at: string
        updated_at?: string
        deleted_at?: string
        tasks: {
            id: number
            paJobId: number
            enabled: boolean
            delayMinutes: number
            rutineType: string
            function: string
            schema?: string
            query?: string
            created_at: string
            updated_at?: string
            deleted_at?: string
        }[]
    }
    created_at: string
    updated_at?: string
    deleted_at?: string
}

const ButtonAction = ({ cta, record, setMainLoading, disabled, onSuccess }: {
    cta: string | undefined
    record: OrderRow
    setMainLoading: Dispatch<SetStateAction<boolean>>
    disabled?: boolean
    onSuccess: () => Promise<void> 
}) => {
    const [debugMode, setDebugMode] = useState<number>(0)
    
    const [modalUpload, setModalUpload] = useState<boolean>(false)
    const [, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false)
    const refUpload = useRef()
    
    const { token } = useSelector((state: any) => state)
    
    /* const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('od', file as RcFile);
        });
        setUploading(true);

        fetch(`https://app.bodegaenlinea.cl/api/v3/sync/${record.job?.tasks[0].function}/${record.erpCliente}/${record.erpEDL ? '1' : '0'}`, {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(() => {
                setFileList([]);
                // setStep(2)
                message.success('Carga enviada a EIT.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
    }; */

    const { fetch: fetchApi, data, errors, loading } = useApi<any>('get', `v3/sync/${record.job?.tasks[0]?.function}/${record.erpId}/${debugMode ? '1' :'0'}`, {
        onRequest: true,
        requiredToken: true
    })

    const e_UpdateStatus = useApi<any>('post', 'v3/sync/postUpdateStatus', {
        onRequest: true,
        requiredToken: true
    })

    useEffect(() => {
        // console.log('e_UpdateStatus.data', e_UpdateStatus.data)
    }, [e_UpdateStatus.data])

    useEffect(() => {
        (async () => {
            if (data?.status === 'success') {
                let ticketResponse = ''
                try {
                    ticketResponse = data?.response?._result?.jsonResponse['soap:Envelope']['soap:Body'][0]['ImportOutboundOrderResponse'][0]['ImportOutboundOrderResult'][0] || ''
                } catch (err) {
                    // Error on asign ticket
                    message.error('Ha ocurrido un error al transmitir la información, intente más tarde.')
                }
                const erpId = record.erpId

                const payload = {
                    response: ticketResponse,
                    erpId
                }

                // Actualiza el estado del ticket
                const _data = await e_UpdateStatus.fetch(payload)
                console.log({ _data })

                // window.location.reload()
                /** @todo No está esperando que se actualice el ticket antes de que ejecute esto. */
                await onSuccess()
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (errors?.length > 0) {
            for (const error of errors) {
                message.error(`Error: ${error.statusMessage}`)
            }
        }
    }, [errors])

    return <>
    <Popconfirm
        disabled
        // disabled={['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`)}
        title={<>¿Estás seguro de {cta?.toLocaleLowerCase()} el documento <strong>{record.erpTipoDocto} {Number(record.erpNumero)}</strong>?<br />
        <small><i>Job name: {btoa(record.jobName)}</i></small></>}
        
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        placement='left'
        onConfirm={() => {
            const _task = record.job?.tasks[0]
            if (_task) {
                switch (_task.rutineType) {
                    case 'upload':
                        console.log({
                            job: record,
                            _task,
                            url: `https://app.bodegaenlinea.cl/api/v3/sync/${record.job?.tasks[0].function}/${record.erpCliente}/${record.erpEDL ? '1' : '0'}`
                        })
                        setModalUpload(true)
                        break;
                    case 'api':
                    default:
                        fetchApi()
                        setDebugMode(0)
                        break;
                }
            }
            /** @todo Use record.job.action */

            // const _next = nextState.get(cta ?? 'Liberar')

            // if (_next) {
            //     fetch()
            // } else {
            //     setMainLoading(true)
            //     history.push(`/orders/${record.orderId}`)
            // }
        }}
        onCancel={() => {
            //
        }}
        okType={'danger'}
        okText='Si'
        cancelText='No'
    >
        <Button
            size='small'
            style={{
                minWidth: 128,
                textAlign: 'left',
                ...(!disabled ? ((cta !== 'Transmitir' ? {
                    background: '#e67e22',
                    borderColor: '#d35400'
                } : {
                    background: '#2ecc71',
                    borderColor: '#27ae60'
                })) : undefined)
            }}
            disabled={disabled}
            type={!['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`) ? 'primary' : undefined}
            icon={<RightOutlined />}
            onClick={() => {
                if (['Despachado', 'PickingParcialAnulado'].includes(`${record.lastState}`)) {

                }
            }}
            loading={loading || e_UpdateStatus.loading}
        >
            {cta}
        </Button>
    </Popconfirm>
    {record.erpTipoDocto === 'NOTA DE VENTA' && <Modal
        title={`Carga Distribución NV ${record.erpNumero}`}
        open={modalUpload}
        key={`Modal${record.erpNumero}`}
        onOk={() => {
            if (!uploading) {
                setModalUpload(false)
            }
        }}
        // confirmLoading={confirmLoading}
        onCancel={() => {
            if (!uploading) {
                setModalUpload(false)
            }
        }}
        okType='default'
        width={768}
        okButtonProps={{
            disabled: uploading,
        }}
        cancelButtonProps={{
            style: {
                display: 'none'
            }
        }}
        okText='Cancelar'
    >
        <Space direction='vertical'>
            <Dragger
                name={'od'}
                multiple={false}
                listType={undefined}
                maxCount={1}
                action={`https://app.bodegaenlinea.cl/api/v3/sync/${record.job?.tasks[0].function}/${record.erpCliente}/${record.erpEDL ? '1' : '0'}`}
                headers={{
                    Authorization: `Bearer ${token}`
                }}
                beforeUpload={(file) => {
                    setFileList([file]);
                    return true;
                }}
                onChange={async (info) => {
                    console.log({ info })
                    const { status } = info.file;

                    if (status !== 'uploading') {
                        // console.log(info.file, info.fileList);
                        setUploading(true)
                    }

                    if (status === 'done') {
                        message.success(`${info.file.name} cargado exitosamente.`);

                        if (info.file.response.status === 'success') {
                            const file = info.file
                            const success = file.response.status === 'success'
                            const answer = file.response.answer

                            const _saveTickets = []
                            for (const _awr of answer) {
                                // ticketResponse = data?.response?._result?.jsonResponse['soap:Envelope']['soap:Body'][0]['ImportOutboundOrderResponse'][0]['ImportOutboundOrderResult'][0] || ''
                                try {
                                    _saveTickets.push(_awr?.answer._result?.jsonResponse['soap:Envelope']['soap:Body'][0]['ImportOutboundOrderResponse'][0]['ImportOutboundOrderResult'][0])
                                } catch (err) {
                                    //
                                }
                            }

                            if (_saveTickets.length > 0) {
                                const erpId = record.erpId

                                const payload = {
                                    response: _saveTickets.join(';;'),
                                    erpId
                                }

                                // Actualiza el estado del ticket
                                const _data = await e_UpdateStatus.fetch(payload)
                                console.log({ _data })

                                // window.location.reload()
                                /** @todo No está esperando que se actualice el ticket antes de que ejecute esto. */
                                await onSuccess()
                            }

                            if (success) {
                                console.log(success)
                                console.log(_saveTickets)
                            }
                        }
                        setUploading(false)
                        setModalUpload(false)
                    } else if (status === 'error') {
                        message.error(`${info.file.name} tuvo problemas, revisa el formato e intenta nuevamente.`);
                        setUploading(false)
                    }
                }}
                // onDrop={(e) => {
                //     console.log('Dropped files', e.dataTransfer.files);
                // }}
                accept=".xlsx"
                ref={refUpload}
            >
                <p className="ant-upload-drag-icon">
                    <FileExcelFilled />
                </p>
                <p className="ant-upload-text">Selecciona el archivo para inyectar</p>
                <p className="ant-upload-hint">
                    Debes cargar archivos exclusivamente de <strong>IOO</strong> (Información para Distribución) para procesar en la inyección a wmsTEK (EIT Logística).
                </p>
            </Dragger>
            {/* <Button block type='primary'>
                Descargar formato de ejemplo
            </Button> */}
        </Space>
    </Modal>}
    </>
}

const usePusher = (channel: string, event: string) => {
    const [messagesChunks, setMessagesChunks] = useState<IPusherMessageChunk[]>([])
    const [messages, setMessages] = useState<IPusherMessage[]>([])

    useEffect(() => {
        const pusher = new Pusher(`b892f7df0be39baa3597`, {
            cluster: 'us2'
        })
        const channel1 = pusher.subscribe(channel);
        channel1.bind(event, function (data: IPusherMessageChunk) {
            try {
                // const incoming = JSON.parse(data.chunk)
                if (data.index === 0 && data.final) {
                    console.log('full individual message')
                    setMessages(actualMessages => [{
                        ...data,
                        processed: false
                    }, ...actualMessages])
                } else {
                    console.log('chunk individual message')
                    setMessagesChunks(actualChunks => [data, ...actualChunks])
                }
            } catch (err) {
                console.error(err)
            }
        })

        return (() => {
            pusher.unsubscribe(channel)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (messagesChunks.length) {
            const [_currentChunk] = messagesChunks
            const { id } = _currentChunk
            // Check if has final index and (index - 1) is same to final
            const hasFinal = messagesChunks.find(mC => mC.final && mC.id === id)
            if (hasFinal) {
                const totalLength = hasFinal.index + 1
                const allChunks = messagesChunks.filter(mC => mC.id === id)
                const hasAllChunks = allChunks.length === totalLength
                if (hasAllChunks) {
                    // Create one message
                    const _message = allChunks.sort((a,b) => a.index - b.index).reduce((acc: IPusherMessage, chunk) => {
                        acc.id = chunk.id
                        acc.chunk = `${acc.chunk}${chunk.chunk}`
                        return acc
                    }, {
                        id: '',
                        index: 0,
                        chunk: '',
                        processed: false,
                        final: true
                    } as IPusherMessage)
                    setMessages(actualMessages => [_message, ...actualMessages])
                }
            }
        }
    }, [messagesChunks])

    useEffect(() => {
        console.log('new m', messages)
    }, [messages])

    return { messages }
}


export default function WarehouseSyncAR({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const [loading, setLoading] = useState(true)
    const [bd, setBd] = useState('')
    const [bdList, setBdList] = useState<string[]>([])
    const [isReady, setIsReady] = useState(false)

    const { messages } = usePusher('bolar-front', 'databases')

    const e_GetDatabases = useApiAR<any>('post', 'custom-query', {
        onRequest: true
    })

    const [records, setRecords] = React.useState<any[]>([])

    const { permissions: _permissions, userInfo: { id_client } } = useSelector((state: any) => state)
    const permissions = _permissions as string[]

    useEffect(() => {
        if (messages.length > 0) {
            const [lastMessage] = messages
            try {
                const incoming = JSON.parse(lastMessage.chunk)
                console.log(incoming)
                switch(incoming.frontKey) {
                    case 'getDatabases':
                        setBdList(incoming.payload.result.map((r: any) => {
                            return r.name
                        }))
                        setIsReady(true)
                        setLoading(false)
                        break;
                    case 'getComp':
                        console.log('getComp: ', incoming)
                        setRecords(incoming.payload.result)
                        console.log(incoming.payload.result)
                        setLoading(false)
                        break;
                    default:
                        console.log('defaultIncoming: ', incoming)
                        break;
                }
            } catch (err) {
                console.error(err)
            }
        }
    }, [messages])

    useEffect(() => {
        e_GetDatabases.fetch({
            "query": "SELECT REPLACE(name, 'DRAGONFISH_', '') as name, database_id FROM sys.Databases WHERE name LIKE 'DRAGONFISH_%' AND name <> 'DRAGONFISH_ZOOLOGICMASTER';",
            "frontKey": "getDatabases"
        })
        setMainLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu(calculateMenu(permissions, id_client))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, exposeSubMenu])

    useEffect(() => {
        if (`${bd}`.length > 0 && bdList.includes(bd)) {
            e_GetDatabases.fetch({
                "query": `SELECT TOP(20) BDALTAFW, CODIGO, DESCFW, EMAIL, ENTREGAPOS, FACTTIPO, FALTAFW, FCLIENTE, FECEXPO, FFCH, FFCHVTOPAG, FIMPUESTO, FLETRA, FMODIFW, FNUMCOMP, FPERSON, FPTOVEN, FSUBTON, FSUBTOT, FTOTAL, FTURNO, FVEN, HALTAFW, HMODIFW, IDCAJA, IVASIS, MONEDA, MONSIS, OPCTRASMIS, SALTAFW, SMODIFW, TIMESTAMP, TOTALCANT FROM [DRAGONFISH_${bd}].[ZooLogic].[COMPROBANTEV] ORDER BY FNUMCOMP DESC;`,
                "frontKey": "getComp"
            })
            setLoading(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bd])

    return (
        <div className="App">
            <div className="Orders">
                <div className="Toolbar" style={{
                    flex: 1,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 24,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Select
                        disabled={loading}
                        loading={!isReady}
                        showSearch
                        allowClear
                        style={{
                            width: '100%',
                            minWidth: 248
                        }}
                        placeholder='Selecciona un depósito...'
                        onSelect={(value) => {
                            console.log(value)
                            setBd(value)
                        }}
                    >
                        {bdList.map(field => (
                            <Select.Option value={field} key={field}>{field}</Select.Option>
                        ))}
                    </Select>
                    
                    {/* <Button loading={loading} onClick={async () => {
                        await e_Sync.refetch()
                        await e_GetList.refetch()
                    }} icon={<ReloadOutlined />}>Obtener nuevos documentos</Button> */}
                </div>
                <div className="Wrapper">
                    <Table
                        dataSource={records}
                        loading={loading}
                        pagination={false}
                        className='TableListAll'
                        size='small'
                        locale={{
                            emptyText: (
                                <Empty
                                    style={{ marginTop: '32px', marginBottom: '48px' }}
                                    description={bd === '' ? `Selecciona una base de datos para comenzar.` : 'No se han encontrado documentos con los criterios de búsqueda.'}
                                >
                                </Empty>
                            )
                        }}
                        footer={() => <>Total de documentos {records.length}</>}
                    >
                        {/* Ícono con el estado de la sincronización */}
                        <Column
                            dataIndex='erpIcon'
                            key='erpIcon'
                            render={(_value, _record) => {
                                return <FileTwoTone />
                            }}
                        />
                        {/* Tipo de Documento ERP */}
                        <Column
                            title='Tipo de Documento'
                            dataIndex='DESCFW'
                            key='DESCFW'
                            sortDirections={['descend', 'ascend']}
                            render={(val: string) => {
                                return `${val.split(' ')[0]}`
                            }}
                            // sorter={(a: OrderRow, b: OrderRow) => a.erpTipoDocto.localeCompare(b.erpTipoDocto)}
                            // filters={records.reduce((acc, order) => {
                            //     if (acc.findIndex((a: ColumnFilterItem) => a.text === order.erpTipoDocto) === -1) {
                            //         acc.push({ text: order.erpTipoDocto, value: order.erpTipoDocto })
                            //     }
                            //     return acc
                            // }, [] as ColumnFilterItem[])}
                            // // defaultFilteredValue={['', 'DocumentoPorDefecto']}
                            // onFilter={(value, record: OrderRow) => record.erpTipoDocto === value}
                        />
                        {/* Número del ERP */}
                        <Column
                            title='Número'
                            dataIndex='FNUMCOMP'
                            key='FNUMCOMP'
                            sortDirections={['descend', 'ascend']}
                            render={(_v, record: any) => {
                                return `${record.FLETRA} ${record.FPTOVEN} ${record.FNUMCOMP}`
                            }}
                            // sorter={(a: any, b: any) => a.erpNumero.localeCompare(b.erpNumero)}
                            // render={(value, record: OrderRow) => {
                            //     return <Tooltip title={`ID: ${record.erpId}`}>{(Number(value))}</Tooltip>
                            // }}
                        />
                        {/* Fecha del ERP */}
                        <Column
                            title='Fecha'
                            dataIndex='FFCH'
                            key='FFCH'
                            sortDirections={['descend', 'ascend']}
                            render={(val) => {
                                return `${val.split('T')[0]}`
                            }}
                            // sorter={(a: OrderRow, b: OrderRow) => {
                            //     const _a = Number(new Date(`${a.erpFecha.split('/').reverse().join('-')}T12:00:00Z`))
                            //     const _b = Number(new Date(`${b.erpFecha.split('/').reverse().join('-')}T12:00:00Z`))
                            //     return _b - _a
                            // }}
                        />
                        {/* Razón Social y RUT */}
                        <Column
                            title='Cliente'
                            dataIndex='FCLIENTE'
                            key='FCLIENTE'
                            // sortDirections={['descend', 'ascend']}
                            // sorter={(a: OrderRow, b: OrderRow) => {
                            //     return a.erpRazonSocial.localeCompare(b.erpRazonSocial)
                            // }}
                            // render={(value, record) => {
                            //     if (!value) {
                            //         return <><strong>GRETA CHILE SPA</strong><br /><small>96942690-0</small></>
                            //     }
                            //     return <><strong>{value}</strong><br /><small>{record.erpCliente}</small></>
                            // }}
                            // filters={records.reduce((acc, order) => {
                            //     if (acc.findIndex((a: ColumnFilterItem) => a.text === order.erpCliente) === -1) {
                            //         acc.push({ text: order.erpRazonSocial, value: order.erpCliente })
                            //     }
                            //     return acc
                            // }, [] as ColumnFilterItem[])}
                            // // defaultFilteredValue={['', 'DocumentoPorDefecto']}
                            // onFilter={(value, record: OrderRow) => record.erpCliente === value}
                        />
                        {/* Datos de la Orden de Compra */}
                        <Column
                            title='Referencia'
                            dataIndex='CODIGO'
                            key='CODIGO'
                            render={(val) => {
                                return `${val.substring(0, 16)}`
                            }}
                            // sortDirections={['descend', 'ascend']}
                            // sorter={(a: OrderRow, b: OrderRow) => {
                            //     const _a = a.erpNumeroOC || a.erpNumero
                            //     const _b = b.erpNumeroOC || b.erpNumero

                            //     return _a.localeCompare(_b)
                            // }}
                            // render={(value, record) => {
                            //     if (!value) {
                            //         return <>{record.erpNumero}<br /><small>{record.erpFecha}</small></>
                            //     }
                            //     return <>OC {record.erpNumeroOC}<br /><small>{record.erpFechaOC}</small></>
                            // }}
                        />
                        {/* Información de Bodegas Virtuales */}
                        <Column
                            title='Total'
                            dataIndex='FTOTAL'
                            key='FTOTAL'
                            align='right'
                            render={(_value, record: any) => {
                                function numberWithCommas(x: number) {
                                    var parts = x.toString().split(".");
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    return parts.join(",");
                                }

                                return <>${numberWithCommas(record.FTOTAL)}</>
                            }}
                        />
                        <Column
                            title='Acciones'
                            key='action'
                            render={(text, record: OrderRow) => {
                                return <Space size='small'>
                                    <ButtonAction
                                        disabled={true}
                                        cta={'Sincronizar'}
                                        record={record}
                                        key={`ButtonAction${record.CODIGO}`}
                                        setMainLoading={setMainLoading}
                                        onSuccess={async () => {
                                            // await e_GetList.refetch()
                                        }}
                                        />
                                </Space>
                            }}
                        />
                    </Table>
                </div>
            </div>
        </div>
    )
}
