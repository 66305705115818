import { Form, Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Rule } from 'antd/lib/form'
import { SelectValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'

import { useApi } from '../../Utils'

export type Categoria = {
    id: number
    name: string
    parentId: number
    subcategories?: Array<Categoria>
    description?: string
    id_client?: number
    created_at?: string
    updated_at?: string
    deleted_at?: string
}

const { Option, OptGroup } = Select

type CategorySelectorProps = {
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
    onFocus?: React.FocusEventHandler<HTMLElement> 
    onChange?: ((value: SelectValue, option: any) => void) | undefined
    onBlur?: React.FocusEventHandler<HTMLElement>
    allowClear?: boolean
    disabled?: boolean
    widthSelect?: string
    help?: string
    size: SizeType
    rules?: Rule[]
}

type CategoryEndpoint = {
    data: Array<Categoria>
}

export function CategorySelector({ widthSelect, help, rules, ...props}: CategorySelectorProps) {
    const { data, loading } = useApi<CategoryEndpoint>('post', 'v3/maestros/categorias/formField')
    const [categorias, setCategorias] = useState<Array<Categoria>>([])

    useEffect(() => {
        if (data && !loading) {
            setCategorias(data.data)
        }
    }, [data, loading])

    return <Form.Item
        label="Categoría"
        name="categoryId"
        help={help}
        rules={rules}
    >
        <Select {...props} style={widthSelect ? { width: widthSelect } : undefined} placeholder='Selecciona una categoría...'>
            {categorias.map((categoria: any) => <OptGroup label={categoria.name} key={`CategoriaId${categoria.id}`}>
                {categoria.subcategories?.map((subcat: any) => <Option value={subcat.id} key={`SubcatId${subcat.id}`}>{subcat.name}</Option>)}
                {!categoria.subcategories?.length && <Option value="-1" key={`EmptySubcat${categoria.id}`} disabled><i>No existen secciones dentro de esta categoría.</i></Option>}
            </OptGroup>)}
        </Select>
    </Form.Item>
}