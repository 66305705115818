import React, { useEffect, useState } from 'react'

import {
    Checkbox,
    InputNumber,
    Tooltip,
    Form,
    Tree,
    Select,
    Tabs,
    Input,
    message
} from 'antd'

import {
    InfoCircleFilled
} from '@ant-design/icons';

import { FormInstance } from 'antd/lib/form';
import { useApi } from '../../../Utils';
import { CategorySelector } from '../../../Components/CategorySelector/CategorySelector';
import { SucursalSelector } from '../../../Components/SucursalSelector/CategorySelector';
import { MastersProductsParams, parseParams } from '../MastersProductsForm';
import { useHistory, useParams } from 'react-router-dom';
import { LaravelEntity } from '../../Dashboard';

const { TabPane } = Tabs
const { Option, OptGroup } = Select
const { TextArea } = Input

/**
 * @todo
 * - [ ] Limpiar código al cambiar `autoAsign`
 */
export interface CreateProps {
    form: FormInstance
    setSelectedMenu: (value: React.SetStateAction<string>) => void
    waiting: number
    setWaiting: (value: React.SetStateAction<number>) => void
    setMainLoading: (value: React.SetStateAction<boolean>) => void
}

export interface Category extends LaravelEntity {
    name: string // "Abarrotes"
    description?: string // null
    parentId?: number // 18
    id_client: number // 4
    // id: 21
    // created_at: "2022-10-29 00:00:00"
    // deleted_at: null
    // updated_at: "2022-10-29 01:42:47
}

export interface BarcodeType extends LaravelEntity {
    barcode: string // "123456789000"
    codigo: string // "PRUEBA-CODE"
    id_client: string // "4"
    id_producto: string // "8736"
    // created_timeago: "1 día antes"
    // updated_timeago: "1 día antes"
}

export interface Product extends LaravelEntity {
    barcode: string // "123456789000"
    categoria: Category //{id: 21, parentId: 18, name: 'Abarrotes', description: null, id_client: 4, …}
    categoryId: number // 21
    cod_interno: string // "PRUEBA-CODE"
    daysActive: string // "* * * * *"
    description?: string // ""
    enabled: boolean // true
    glosa: string // "Código de Prueba DLX"
    hasExpires: boolean // false
    hasInternalControl: boolean // false
    hasManualPrice: boolean // false
    hasStockControl: boolean // true
    hasVat: boolean // true
    id_client: number // 4
    image?: string // ""
    internalNotes?: string // ""
    maxStock: number // 0
    minStock: number // 0
    name: string // "Código de Prueba DLX"
    priceBuy?: number // 0
    priceNet?: number // 0
    priceVAT?: number // 0
    priceTotal: number // 0
    typeProduct: number // 1
    unit: string // ""
    barcodes: BarcodeType[]
    // updated_timeago: "1 día antes"
    // created_timeago: "1 día antes"
}

export default function CollaboratorCreate({ form, setSelectedMenu, waiting, setWaiting, setMainLoading }: CreateProps) {
    const { productId } = parseParams(useParams<MastersProductsParams>())
    const [mode, ] = useState<'create'|'edit'>(productId ? 'edit' : 'create')

    const [autoAsign, setAutoAsign] = useState<boolean>(false)
    const [manualPrice, setManualPrice] = useState<boolean>(false)
    const [product, setProduct] = useState<Product>()

    const history = useHistory()

    const { data: dataRead, loading: loadingRead, fetch: readProduct } = useApi<any>('post', 'v3/maestros/productos/read', {
        requiredToken: true,
        onRequest: true
    })

    const { data, loading, errors, fetch: createProduct } = useApi('post', `v3/maestros/productos/${mode}`, {
        requiredToken: true,
        onRequest: true
    })

    const treeData = [
        {
            title: 'Producto activo',
            key: 'active'
        },
        {
            title: 'I.V.A. Chile 19%',
            key: 'vat'
        },
        {
            title: 'Control Interno',
            key: 'hasInternalControl'
        },
        {
            title: 'Precio Manual',
            key: 'hasManualPrice'
        },
        {
            title: 'Control de Stock',
            key: 'checkStock'
        },
        {
            title: 'Fecha de Vencimiento',
            key: 'expiresDate'
        },
        {
            title: 'Corrección Monetaria',
            key: 'cm',
            disabled: true
        },
        {
            title: 'Depreciación',
            key: 'depreciacion',
            disabled: true
        },
        {
            title: 'Costeable',
            key: 'costeable',
            disabled: true
        }
    ];

    useEffect(() => {
    // console.log({})
        if (productId) {
            readProduct({ productId })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId])

    useEffect(() => {
        if (dataRead?.data?.record) {
            setProduct(dataRead.data.record)
            // form.setFieldsValue(dataRead.data.record)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRead])

    useEffect(() => {
        if (product) {
            const configExtra = []

            // Configuración del producto `active`
            if (product.enabled) {
                configExtra.push('active')
            }
            // Configuración del producto `vat`
            if (product.hasVat) {
                configExtra.push('vat')
            }
            // Configuración del producto `hasInternalControl`
            if (product.hasInternalControl) {
                configExtra.push('hasInternalControl')
            }
            // Configuración del producto `hasManualPrice`
            if (product.hasManualPrice) {
                configExtra.push('hasManualPrice')
            }
            // Configuración del producto `checkStock`
            if (product.hasStockControl) {
                configExtra.push('checkStock')
            }
            // Configuración del producto `expiresDate`
            if (product.hasExpires) {
                configExtra.push('expiresDate')
            }
            
            form.setFieldsValue({
                code: product.cod_interno,
                autoAssignCode: false,
                categoryId: product.categoryId,
                type: `${product.typeProduct}`,
                glosa: product.glosa,
                description: product.description,
                configExtra,
                barcodes: product.barcodes.map((barcode: BarcodeType) => barcode.barcode),
                priceTotal: product.priceTotal,
                comments: product.internalNotes,
            })
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [product])

    useEffect(() => {
        setMainLoading(loadingRead)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRead])

    useEffect(() => {
        if (autoAsign) {
            const currentValues = form.getFieldsValue()
            form.setFieldsValue({
                ...currentValues,
                code: ''
            })
        }
    }, [autoAsign, form])

    useEffect(() => {
        if (!loading && (data || errors.length > 0)) {
            setWaiting(0)
            setMainLoading(false)
            if (errors.length > 0) {
                message.error({
                    content: <>Oops, ha ocurrido un error.<br /><strong>Detalles: </strong>{errors[0].statusMessage}</>,
                    duration: 10,
                    key: 'createProduct'
                })
            } else {
                message.success({
                    content: `Producto ${mode === 'create' ? 'creado' : 'editado'} con éxito.`,
                    duration: 5,
                    key: 'createProduct'
                })
                form.resetFields()
                history.push('/masters/products/')
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, errors, setSelectedMenu, setMainLoading, setWaiting])

    useEffect(() => {
        if (waiting > 0) {
            message.loading({
                content: `${mode === 'create' ? 'Creando nuevo' : 'Editando'} producto...`,
                duration: waiting,
                key: 'createProduct'
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waiting])

    const nextElement = (event: any) => {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }
      
    return <Form
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 18 }}
    form={form}
    initialValues={{
        autoAssignCode: false,
        configExtra: ['active', 'vat', 'checkStock'],
        // type: "Existencia",
        type: "1",
        minStock: 0,
        maxStock: 0,
        barcodes: [],
        sucursalesAvailability: ['SUC-1', 'SUC-2', 'SUC-3', 'SUC-4', 'SUC-5', 'SUC-6', 'SUC-7', 'SUC-8']
    }}
    onValuesChange={(changedValues, allValues) => {
        const _manualPrice = allValues?.configExtra?.includes("hasManualPrice")
        if (manualPrice !== _manualPrice) {
            setManualPrice(_manualPrice)
            if (_manualPrice) {
                form.setFieldsValue({
                    price: 0
                })
            } else {
                form.setFieldsValue({
                    price: undefined
                })
            }
        }

    }}
    onFinish={(values) => {
        if (typeof values.barcodes !== 'object') {
            return message.error(<>Falta completar información en <strong>Atributos</strong>.</>);
        }
        setWaiting(30)
        setMainLoading(true)
        
        if (mode === 'edit') {
            values.productId = productId
        }
        createProduct(values)
    }}
    onFinishFailed={(errorInfo) => {
        const keysErrors = errorInfo.errorFields.map(e => e.name[0])
        const isAtributosTab = keysErrors.includes('barcodes')
        const isProductoTab = keysErrors.length - (isAtributosTab ? 1 : 0) > 0
        const tabs = []
        
        if (isProductoTab) {
            tabs.push('Producto')
        }
        if (isAtributosTab || typeof errorInfo.values.barcode !== 'object') {
            tabs.push('Atributos')
        }
        return message.error(<>Falta completar información en <strong>{tabs.join(' y ')}</strong></>)
        
        // console.log({ errorInfo })
    }} >
        <Tabs tabPosition={'top'} type='card'>
            <TabPane tab='Producto' key="1">
                <Form.Item label='&nbsp;' className='hideLabel' name='autoAssignCode' valuePropName="checked">
                    <Checkbox disabled defaultChecked={autoAsign} onChange={(checked) => {
                        setAutoAsign(checked.target.checked)
                    }}>Asignar código automáticamente</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Código"
                    name="code"
                    help='Código alfanumérico para identificar tu producto.'
                    rules={[{ required: !autoAsign, message: 'Debes ingresar un código interno para tu producto' }]}
                >
                    <Input
                        size='large'
                        style={{ width: 250, marginRight: 16 }}
                        onKeyDown={nextElement}
                        disabled={autoAsign || !!product?.cod_interno}
                        autoFocus />                        
                </Form.Item>
                <CategorySelector
                    help="Categoría para informes del sistema."
                    size='large'
                    rules={[{ required: true, message: 'Debes seleccionar una categoría para tu producto' }]}
                    widthSelect={'95%'}  />
                <br/><br />
                <Form.Item
                    label="Tipo"
                    name="type"
                    rules={[{ required: true, message: 'Debes seleccionar un tipo de producto' }]}
                >
                    <Select size='large' style={{ width: '80%' }} placeholder='Selecciona un tipo de producto...' onKeyDown={nextElement} onChange={(value: any) => {
                        value = Number(value)
                        if (value === 2){
                            // Servicio
                            const currentValues = form.getFieldsValue()
                            form.setFieldsValue({
                                ...currentValues,
                                configExtra: currentValues.configExtra.filter((k: string) => k !== 'checkStock')
                            })
                        } else {
                            // Existencia
                            const currentValues = form.getFieldsValue()
                            form.setFieldsValue({
                                ...currentValues,
                                configExtra: [
                                    ...currentValues.configExtra.filter((k: string) => k !== 'checkStock'),
                                    'checkStock'
                                ]
                            })
                        }
                    }}>
                        <OptGroup label={<>Sistema&nbsp;<Tooltip title={<>Tipos de productos por defecto cargados en <strong>Bodega en Línea</strong></>}><InfoCircleFilled /></Tooltip></>}>
                            <Option value="1">Existencia</Option>
                            <Option value="2">Servicio</Option>
                        </OptGroup>
                        <OptGroup label="Personalizados">
                            <Option value="-1" disabled><i>No existen tipos personalizados.</i></Option>
                        </OptGroup>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Glosa"
                    name="glosa"
                    rules={[{ required: true, message: 'Debes ingresar una glosa para tu producto' }]}
                >
                    <Input size='large' onKeyDown={nextElement} style={{ width: '95%' }} />
                </Form.Item>
                <Form.Item
                    label="Descripción"
                    name="description"
                >
                    <TextArea onKeyDown={nextElement} style={{ width: '95%' }} />
                </Form.Item>
                <Form.Item
                    label="Configuracion adicional"
                    name="configExtra"
                    valuePropName="checkedKeys"
                    trigger="onCheck"
                >
                    <Tree
                        checkable
                        selectable={false}
                        treeData={treeData}
                        />
                </Form.Item>
            </TabPane>

            <TabPane tab="Atributos" key="2">
                <Form.Item
                    label='Códigos de barra'
                    name='barcodes'
                    rules={[{ required: true, message: 'Debes ingresar a lo menos un código para tu producto' }]}
                    >
                    <Select size={'large'} mode='tags' placeholder='Escanea códigos de barras aquí...' autoFocus />
                </Form.Item>
                
                <Form.Item
                    label={`Precio Venta`}
                    name="priceTotal"
                    key={'priceTotal'}
                    rules={[{
                        required: !manualPrice, message: 'Debes ingresar un precio de venta para tu producto' }
                    ]}
                >
                    <InputNumber
                        size='large'
                        disabled={manualPrice}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                        style={{
                            minWidth: 300
                        }}
                        onKeyDown={(e) => {
                            nextElement(e)
                        }} />                        
                </Form.Item>

                {/* <Form.Item
                    label="Stock Máximo"
                    name="minStock"
                >
                    <InputNumber
                        size='large'
                        onKeyDown={nextElement} />                        
                </Form.Item> */}

                {/* <Form.Item
                    label="Stock Máximo"
                    name="maxStock"
                >
                    <InputNumber
                        size='large'
                        onKeyDown={nextElement} />                        
                </Form.Item> */}

                <Form.Item
                    label="Notas internas"
                    name="comments"
                >
                    <TextArea style={{ width: '95%' }} rows={4} />
                </Form.Item>
            </TabPane>

            <TabPane tab="Disponibilidad" key="3">
                <SucursalSelector
                    help="Sucursales donde el producto estará disponible."
                    size='large'
                    // rules={[{ required: true, message: 'Debes seleccionar una categoría para tu producto' }]}
                    widthSelect={'95%'}  />
            </TabPane>

            <TabPane tab="Contabilidad" disabled key="4">
                No disponible en esta versión.
            </TabPane>

            <TabPane tab="Ofertas" disabled key="5">
                No disponible en esta versión.
            </TabPane>
        </Tabs>
    </Form>
}
