import React, { useEffect } from 'react'

import {
  HomeOutlined,
  MobileOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'

import {
  Card,
  Row,
  Col,
  Statistic,
  Tooltip,
  Table,
  Badge
} from 'antd'

import useApi from '../../Utils/useApi'

import { format } from 'date-fns'

// import udcoDashboard from '../../Assets/udcoDashboard.svg'

import './Dashboard.scss'
import { ColumnsType } from 'antd/lib/table'

export interface LaravelEntity {
  id?: number
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface Sucursal extends LaravelEntity {
  key?: number | string
  clientId: number
  clientRazonSocialId: number
  internalName: string
  address: string
  commune: string
  city: string
  razonSocial?: ClientRazonSocial
}
export interface ClientRazonSocial extends LaravelEntity {
  clientId: number
  socialName: string
  identityNumber: number
  cheapRide: string
  cheapRideNumber: number
  address: string
  commune: string
  city: string
  sucursalId: number
  sucursales?: Sucursal[]
  key?: string | number
}

interface Client extends LaravelEntity {
  name: string
  rut: string
  mail: string
  limitInventories?: number
  limitProducts?: number
  limitAccounts?: number
  limitSubUsers?: number
  razones_sociales: ClientRazonSocial[]
}

interface Stadistics {
  departamentos: any
  ventaDiaria: number
}

type DashboardEndpoint = {
  client: Client
  estadisticas: Stadistics
  inventoriesFinished: number
  inventoriesCreated: number
  procesosActive: any[]
  productsRegistered: number
  usersActive: any[]
  usersRegistered: number
}

function Dashboard({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const { data, loading } = useApi<DashboardEndpoint>('get', 'dashboard/index')

  useEffect(() => {
    if (!!exposeSubMenu) {
      exposeSubMenu([{ key: 'index', name: 'Resumen', icon: <HomeOutlined /> }])
    }

    return () => {
      //
    }
  }, [exposeSubMenu])

  useEffect(() => {
    console.log({ data })
    setMainLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const columns: ColumnsType<Sucursal> = [
    {
      title: 'Nombre',
      dataIndex: 'internalName',
      key: 'internalName',
      render: (text: string, record: Sucursal) => (
        <Tooltip title={record.razonSocial?.socialName}>
          <Badge status='success' /> <span>{text}</span>
        </Tooltip>
      )
    },
    {
      title: 'Colaborador/a',
      dataIndex: 'employee',
      key: 'employee',
      render: (text: string, record: Sucursal) => (
        <Tooltip title={record.razonSocial?.socialName}>
            <Badge status='warning' /> <i>Sin información</i>
        </Tooltip>
        )
    },
    {
      title: 'Venta diaria',
      dataIndex: 'ventaDiaria',
      key: 'ventaDiaria',
      render: (value: number, record: Sucursal) => (
        <Statistic
          valueStyle={{ color: '#3f8600', fontSize: '1em' }}
          prefix={'$'}
          groupSeparator='.'
          value={value}
        />
      )
    },
    // {
    //   title: 'Acciones',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: () => {
    //     return <>WEA</>
    //   }
    // }
    // {

    // }
  ]

  return (
    <div className='App'>
      <div className='Dashboard'>
        <div className='Wrapper'>
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='Dashboard__Heading'
            >
              <h3>Información General</h3>
            </Col>
            {/* <Col span={24} order={isMobile ? 0 : 5}>
                            <Result
                                status="success"
                                title="¡Bienvenido a Bodega en Línea!"
                                subTitle="Conoce todas las opciones de tu nuevo software en el menú superior, si necesitas asistencia, haz click en el botón."
                                icon={<img src={udcoDashboard} className="Dashboard--WelcomeImage" alt="Imagen de bienvenida" />}
                                extra={<Button href='mailto:contacto@bol.cl' type="ghost">Contáctanos</Button>}
                            />
                        </Col> */}
            <Col xs={24} md={6}>
              <Card
                loading={loading}
                size='small'
                title={
                  <>
                    <MobileOutlined /> Usuarios en línea
                  </>
                }
              >
                No hay usuarios conectados realizando inventario en este
                momento.
              </Card>
            </Col>
            <Col xs={24} md={6}>
              <Card
                loading={loading}
                size='small'
                title={
                  <>
                    <CodeSandboxOutlined /> Inventarios en Curso
                  </>
                }
              >
                No hay procesos de inventario activos en este momento.
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                loading={loading}
                size='small'
                title={
                  <>
                    <DashboardOutlined /> Configuración para "
                    {data?.client?.name}"
                  </>
                }
              >
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Statistic
                      title={
                        <>
                          Usuarios conectados&nbsp;
                          <Tooltip title='Consulta a tu ejecutivo para ampliar este lìmite'>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      }
                      value={(data?.usersRegistered ?? 0) + 1}
                      groupSeparator='.'
                      suffix={`/ ${data?.client?.limitSubUsers ?? '∞'}`}
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <Statistic
                      title={
                        <>
                          Productos registrados&nbsp;
                          <Tooltip title='Consulta a tu ejecutivo para ampliar este lìmite'>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      }
                      value={data?.productsRegistered}
                      groupSeparator='.'
                      suffix={`${`/ ${data?.client?.limitProducts ?? '∞'}`
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <Statistic
                      title='Inventarios realizados'
                      groupSeparator='.'
                      value={data?.inventoriesCreated}
                      suffix={`${`/ ${data?.client?.limitInventories ?? '∞'}`
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            {data?.client?.razones_sociales && data?.client?.razones_sociales.length > 0 && (
              <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='Dashboard__Heading'
                >
                  <h3>Módulo: Punto de Venta</h3>
                </Col>
                <Col xs={24} md={6}>
                  <Card
                    loading={loading}
                    size='small'
                    title={`Estadísticas de hoy ${format(
                      new Date(),
                      'dd-MM-yyyy'
                    )}`}
                  >
                    <Statistic
                      title={`Venta diaria`}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={'$'}
                      groupSeparator='.'
                      value={data?.estadisticas?.ventaDiaria}
                    />
                    <Statistic
                      title={`Venta Cigarros`}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={'$'}
                      groupSeparator='.'
                      value={data?.estadisticas.departamentos['Cigarros'] ?? 0}
                    />
                    <Statistic
                      title={`Venta de Pago Servicios`}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={'$'}
                      groupSeparator='.'
                      value={
                        data?.estadisticas.departamentos['Pago de Servicios'] ??
                        0
                      }
                    />
                  </Card>
                </Col>
                <Col xs={24} md={18}>
                  <Table
                    size='middle'
                    dataSource={data?.client?.razones_sociales.reduce<
                      Sucursal[]
                    >((acc, rz) => {
                      rz.sucursales?.forEach((s) => {
                        s.key = s.id
                        s.razonSocial = {
                          ...rz,
                          sucursales: undefined
                        }
                        acc.push(s)
                      })
                      return acc
                    }, [])}
                    columns={columns}
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
