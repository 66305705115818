import React, { useEffect, useState } from 'react'

import {
    CheckSquareOutlined,
    AlertOutlined
} from '@ant-design/icons';

import {
    Button,
    Input,
    Space,
    Drawer,
    Table,
    Row,
    Col,
    Statistic,
    Modal
} from 'antd';

import { ColumnsType } from 'antd/lib/table';
import { InputStatus } from 'antd/lib/_util/statusUtils';
import moment from 'moment';

import { useApi } from '../../../Utils';
import { NowIntegrate } from '../../../Assets';

export default function DrawerResume ({ data, resetAll, modify = true, price = true }: any) {
    const [modalShow, setModalShow] = useState(false)
    
    const [resume, setResume] = useState({
        files: 0,
        orders: 0,
        total: 0,
        date: moment().format('YYYY-MM-DD')
    })

    const [localData, setLocalData] = useState<any[]>(data)

    const getMainConfig = () => {
        return data?.length > 0 ? data[0].formConfig : null
    }

    const { data: responseApi, loading, fetch } = useApi<any>('post', `v3/sync/saveInjector/${getMainConfig()?.rut}`, {
        requiredToken: true,
        onRequest: true
    })

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (data?.length > 0) {
            console.log('DEBUG: ', data, typeof data)
            const _d = [...data]
            console.log(_d)

            let iD = 0
            for (const { number, detail } of _d) {
                _d[iD].detail = detail.map((d: any) => {
                    if (!d.FORCE) {
                        const [serie, numero] = d.OCF.split('-')
                        const internalCode = numero.slice(0, 3)
    
                        d.OCN = `${internalCode}${`${number.slice((number.length - 10 < 0 ? 0 : number.length - 10), number.length)}`.padStart(10, '0')}`
                        d.OCF = `${serie}-${d.OCN}`
                    }
                    return d
                })
                iD++
            }

            setLocalData(_d)
        }
    }, [data])

    useEffect(() => {
        if (localData?.length > 0) {
            setOpen(true)

            let _total = 0

            const _resume = localData.reduce((acc: any[], { detail }: any) => {
                const ocs = detail.reduce((_acc: any[], { OCF, CANT, COST }: any) => {
                    _total += (CANT * COST)
                    if (!_acc.includes(OCF)) {
                        _acc.push(OCF)
                    }
                    return _acc
                }, [])
                return [...acc, ...ocs]
            }, [])

            setResume({
                files: localData.length,
                orders: _resume.length,
                total: _total,
                date: moment().format('YYYY-MM-DD')
            })
        }
    }, [localData])

    useEffect(() => {
        console.log(responseApi)
        if (responseApi?.status === 'success') {
            setModalShow(true)
            // message.success('Órdenes cargadas exitosamente. Conéctate a tu ERP y continua el proceso desde "Integrador de Documentos".', 15);
            // setOpen(false)
            // resetAll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseApi])

    const onClose = () => {
        setOpen(false);
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Cód. Greta',
            dataIndex: 'CODE',
            key: 'CODE',
            render: (value, record, index) => {
                let status = '' as InputStatus

                // if (!value) {
                //     return <Tag color="yellow">Sin sincronizar</Tag>
                // }

                if (record.EAN === -1 || !value) {
                    status = 'error'
                }

                if (modify) {
                    return <Input key={`InputKey${index}`} status={status} prefix={status === '' ? <CheckSquareOutlined /> : <AlertOutlined />} defaultValue={value} onBlur={(e) => {
                        const currentData = { ...localData }
                        currentData[0].detail[index].CODE = e.currentTarget.value
                        // currentData[0].detail[index].EAN = 0
    
                        setLocalData(currentData)
                    }} placeholder="XXXXXX-12345678" />
                } else {
                    return value
                }

            }
        },
        // {
        //     title: 'Cód. EAN13',
        //     dataIndex: 'EAN',
        //     key: 'EAN',
        // },
        ...(price ? [{
            title: 'SKU Cliente',
            dataIndex: 'SKU',
            key: 'SKU',
        }] : []), 
        {
            title: 'Descripción',
            dataIndex: 'DESC',
            key: 'DESC',
        },
        {
            title: 'Cantidad',
            dataIndex: 'CANT',
            key: 'CANT',
        },
        ...(price ? [{
            title: 'Unitario',
            dataIndex: 'COST',
            key: 'COST',
        }] : []),
    ];

    const send = () => {
        console.log({
            orders: localData,
            edl: localData[0].formConfig.isLimitedEdition
        })

        fetch({
            orders: localData,
            edl: localData[0].formConfig.isLimitedEdition
        })
    }

    const closeAndReset = () => {
        setOpen(false)
        setModalShow(false)
        resetAll()
    }


    return <>
        <Modal
            width={640 + (24 * 2)}
            title="Documentos cargados con éxito"
            open={modalShow}
            afterClose={() => {
                closeAndReset()
            }}
            onOk={() => {
                window.open('ms-rd:')
                closeAndReset()
            }}
            onCancel={closeAndReset}
            cancelText={'Continuar'}
            okText={'Ir a Manager ERP'}
        >
            <img src={NowIntegrate} alt="Intrucciones para Integrar Documentos en Manager ERP" />
            <p>Los documentos fueron cargados exitosamente. Conéctate a tu ERP y continua el proceso desde "Integrador de Documentos".</p>
        </Modal>
        <Drawer
            title={`Resumen de la Carga`}
            placement="right"
            size={'large'}
            width={1000}
            onClose={onClose}
            open={open}
            extra={
                <Space>
                    <Button onClick={onClose} disabled={loading}>Cancelar</Button>
                    <Button loading={loading} type="primary" onClick={send}>Procesar {price ? 'órdenes' : 'documento'}</Button>
                </Space>
            }
        >
            {price && <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col span={6}>
                    <Statistic title="Archivos cargados" value={resume.files} />
                </Col>
                <Col span={6}>
                    <Statistic title="Órdenes generadas" value={resume.orders} suffix={`/ ${data?.length} OC`} />
                </Col>
                <Col span={6}>
                    <Statistic title="Monto total" value={`$${resume.total.toLocaleString().replaceAll(',', '.')}`} />
                </Col>
                <Col span={6}>
                    <Statistic title="Fecha de Carga" value={resume.date} />
                </Col>
            </Row>}
            {data?.map(order => <Table dataSource={order?.detail} columns={columns} pagination={{
                pageSize: 1000,
                disabled: true
            }} />)}
        </Drawer>
    </>
}