import React, { useState, useEffect } from 'react'

import { CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'

import {
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Popconfirm,
  Space,
  Statistic,
  Table
} from 'antd'

import { useApi } from '../../../Utils'
import { CategorySelector } from '../../../Components/CategorySelector/CategorySelector'
import { useHistory } from 'react-router-dom'

const { Column } = Table

export default function ProductAll() {
  const { data, loading, refetch } = useApi<any>(
    'post',
    'v3/maestros/productos/list'
  )
  const {
    data: toggleData,
    loading: toggleLoading,
    fetch: toggleFetch
  } = useApi<any>('post', 'v3/maestros/productos/toggle', {
    requiredToken: true,
    onRequest: true
  })

  const [refetchParams, setRefetchParams] = useState<any>(null)
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    console.log(toggleData)
    if (toggleData?.status === 'success') {
        refetch(refetchParams)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleData])

  useEffect(() => {
    if (refetchParams) {
      refetch(refetchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchParams])

  const initialValues = {
    // hasVat: true,
    hasInternalControl: null,
    // hasStockControl: true,
    enabled: true,
    search: '',
    categoryId: null
  }

  return (
    <Space size='middle' direction='vertical'>
      <Form
        initialValues={initialValues}
        layout={'inline'}
        form={form}
        onFinish={(values) => {
          if (JSON.stringify(values) !== JSON.stringify(refetchParams)) {
            setRefetchParams(values)
          }
        }}
      >
        <Form.Item label='Glosa o Código' name='search'>
          <Input
            placeholder='Ingresa el código de barras o nombre del producto...'
            disabled={loading}
            allowClear={true}
            onBlur={() => form.submit()}
            onKeyDown={(event) =>
              event.key === 'Enter' ? form.submit() : null
            }
            size={undefined}
            // style={{ width: '250px' }}
          />
        </Form.Item>
        <CategorySelector
          disabled={loading}
          allowClear={true}
          onChange={() => form.submit()}
          size={undefined}
          // widthSelect={'calc(20vw)'}
        />
        <Form.Item
          name='hasInternalControl'
          valuePropName='checked'
          className='CheckedClass'
        >
          <Checkbox
            indeterminate={form.getFieldValue('hasInternalControl') === null}
            onChange={() => form.submit()}
          >
            Control Interno
          </Checkbox>
        </Form.Item>
        <Form.Item
          name='enabled'
          valuePropName='checked'
          className='CheckedClass'
        >
          <Checkbox onChange={() => form.submit()}>Vigente</Checkbox>
        </Form.Item>
      </Form>

      <Table
        dataSource={data?.data?.records?.data}
        loading={loading || toggleLoading}
        pagination={{ ...data?.data?.pagination, position: ['bottomRight'] }} // 'topRight',
        onChange={(pagination, filters, sorter, extra) => {
          refetch({
            page: pagination.current,
            ...refetchParams
          })
        }}
        className='TableListAll'
        size='small'
        locale={{
          emptyText: (
            <Empty
              style={{ marginTop: '32px', marginBottom: '48px' }}
              description='No se han encontrado productos con los criterios de bùsqueda.'
            >
              <Button
                ghost
                type='primary'
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setTimeout(() => form.submit(), 100)
                }}
              >
                Reestablecer filtros
              </Button>
            </Empty>
          )
        }}
        footer={() => <>Total de productos {data?.data?.pagination?.total}</>}
      >
        <Column
          title='Glosa'
          dataIndex='name'
          key='name'
          render={(value, record: any) => {
            const description = new Map([
              [1, 'Existencia'],
              [2, 'Servicio']
            ])
            return (
              <span style={{ lineHeight: '10px' }}>
                <strong>{value}</strong>
                <br />
                <small>
                  ({record.cod_interno}) -{' '}
                  {description.get(Number(record.typeProduct))}
                </small>
              </span>
            )
          }}
        />
        <Column
          title='Categoría'
          dataIndex='categoryId'
          key='categoryId'
          render={(value, record: any) => {
            return <>{record.categoria.name}</>
          }}
        />
        {/* <Column title="Control Stock" dataIndex="hasStockControl" key="hasStockControl" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        <Column
          title='Precio Venta'
          dataIndex='priceTotal'
          key='priceTotal'
          align='right'
          render={(value: number, record: any) => {
            return (
              <Statistic
                valueStyle={{
                  color: record.hasManualPrice ? '#1890ff' : '#3f8600',
                  fontSize: '1em'
                }}
                prefix={record.hasManualPrice ? '' : '$'}
                groupSeparator='.'
                value={record.hasManualPrice ? 'Manual' : value}
              />
            )
          }}
        />
        {/* <Column title="Mìnimo" dataIndex="minStock" key="minStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="Máximo" dataIndex="maxStock" key="maxStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="IVA" dataIndex="hasVat" key="hasVat" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        {/* <Column title="Caducidad" dataIndex="hasExpires" key="hasExpires" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        <Column
          title='Control Interno'
          dataIndex='hasInternalControl'
          key='hasInternalControl'
          align='center'
          render={(value) =>
            value ? (
              <CheckCircleFilled
                style={{
                  color: '#1890ff'
                }}
              />
            ) : (
              <CloseCircleFilled
                style={{
                  color: '#00000040'
                }}
              />
            )
          }
        />

        <Column
          title='Precio Manual'
          dataIndex='hasManualPrice'
          key='hasManualPrice'
          align='center'
          render={(value) =>
            value ? (
              <CheckCircleFilled
                style={{
                  color: '#1890ff'
                }}
              />
            ) : (
              <CloseCircleFilled
                style={{
                  color: '#00000040'
                }}
              />
            )
          }
        />

        <Column
          title='Vigente'
          dataIndex='enabled'
          key='enabled'
          align='center'
          render={(value, record: any) =>
            value ? (
              <CheckCircleFilled
                style={{
                  color: '#52c41a'
                }}
              />
            ) : (
              <CloseCircleFilled
                style={{
                  color: '#00000040'
                }}
              />
            )
          }
        />

        <Column
          title='Opciones'
          key='action'
          render={(text, record: any) => (
            <Space size='middle'>
              <Button
                onClick={() => {
                  history.push(`/masters/products/edit/${record.id}`)
                }}
                loading={toggleLoading}
                size='small'
                type='primary'
                ghost
              >
                Editar
              </Button>
              <Popconfirm
                title={`¿Estás seguro de ${
                  record.enabled ? 'desactivar' : 'activar'
                } el producto ${record.name}?`}
                icon={<QuestionCircleOutlined style={{ color: record.enabled ? 'red' : 'blue' }} />}
                placement='left'
                onConfirm={() => {
                  toggleFetch({
                    productId: record.id
                  })
                }}
                onCancel={() => {
                  //
                }}
                okType={record.enabled ? 'danger' : 'primary'}
                okText='Si'
                cancelText='No'
              >
                <Button
                  size='small'
                  type={record.enabled ? undefined : 'primary'}
                  loading={toggleLoading}
                  danger={record.enabled}
                >
                  {record.enabled ? 'Desactivar' : 'Activar'}
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </Space>
  )
}
