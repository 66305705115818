import { FormInstance, UploadFile, UploadProps, message } from "antd";
import sanitizeOrderNumber from "./sanitizeOrderNumber";

export default function createUploaderProps (rut: string, token: string, form: FormInstance<any>, accept = '.txt', limitItems = 60, action = '', name = 'oc', success?: (file: UploadFile<any>) => void): UploadProps {
    return {
        name,
        multiple: false,
        maxCount: 1,
        action: !action ? `https://app.bodegaenlinea.cl/api/v3/sync/injector/${rut}?limitItems=${limitItems}` : action,
        // method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        onChange(info) {
            const { status } = info.file;

            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} cargado exitosamente.`);

                const { isImported, isLoaded, correlativo, tascam, requireFolio = false } = info.file.response

                if (isImported || isLoaded) {
                    message.warning(`${info.file.name} ya ha sido ${isImported && 'importado'}${isLoaded && 'inyectado'} anteriormente. Revisa en el panel de "Importador de Documentos" en tu ERP.`);
                    return
                }

                if (info.file.response.status === 'success') {
                    // const _orders = form.getFieldValue('orders') ?? [];
                    form.setFieldsValue({
                        orders: [
                            // ..._orders,
                            {
                                path: info.file.response.path,
                                number: info.file.response.number || sanitizeOrderNumber(info.file.name),
                                filename: info.file.name,
                                detail: info.file.response.redux,
                                config: {
                                    tascam,
                                    correlativo,
                                    requireFolio,
                                }
                            }
                        ]
                    })
                    if(success) {
                        success(info.file)
                    }
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} tuvo problemas, revisa el formato e intenta nuevamente.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove(file) {
            const _orders = form.getFieldValue('orders') ?? [];
            form.setFieldsValue({ orders: _orders.filter((item: any) => item.path !== file.response.path) })
        },
        accept
    };
}