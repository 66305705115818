import React from "react";
import { useEffect, useState, Fragment } from "react"

import {
    HomeOutlined,
    MoneyCollectOutlined,
    InboxOutlined
} from '@ant-design/icons';

import readXlsxFile, { Row, readSheetNames } from 'read-excel-file'
import Dragger from "antd/lib/upload/Dragger";
import { Button, Descriptions, Drawer, Form, Radio, Result, Select, Skeleton, Space, Steps, Table, UploadProps, message } from "antd";

import './EmissionFact.scss'
import { useForm } from "antd/lib/form/Form";
import { useApi } from "../../Utils";
import { useSelector } from "react-redux";

const nf = new Intl.NumberFormat('es-CL')

const parseRows = (rows: Row[]) => {
    const dataSource = []
    const columns = []

    let j = 0
    for (const row of rows) {
        let i = 0
        const item = {
            key: `${j}`
        } as any
        for (const column of row) {
            if (j === 0) {
                columns.push({
                    title: `${String.fromCharCode(97 + i).toUpperCase()}`,
                    dataIndex: `col${i}`,
                    key: `col${i}`
                })
            }

            item[`col${i}`] = column
            i++
        }
        j++
        dataSource.push(item)
    }
    return {
        dataSource,
        columns
    }
}

function DynamicTable ({ rows, onChange }: {
    rows: Row[],
    onChange: (selectedRow: number) => void
}) {
    const { dataSource, columns } = parseRows(rows)
    
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            onChange(Number(selectedRowKeys))
        },
    };
    
    return <Table scroll={{
        x: 130
    }} pagination={false} dataSource={dataSource} columns={columns} rowSelection={{ type: 'radio', ...rowSelection }} />
}

export default function EmissionFact({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const [drawer, setDrawer] = useState(false)
    const [radioButton, setRadioButton] = useState<string>()
    const [rowSelector, setRowSelector] = useState<number>()
    const [step, setStep] = useState(1)
    const [form] = useForm()
    const { permissions } = useSelector((state: any) => state)
    
    const { data, loading, fetch } = useApi<any>('post', 'v3/sync/postCreateDocument', {
        onRequest: true,
        requiredToken: true
    })

    // Archivo cargado
    const [file, setFile] = useState<File>()
    // Listado de hojas del archivo
    const [sheetNames, setSheetNames] = useState<string[]>([])
    // Hoja seleccionada
    const [selectedSheet, setSelectedSheet] = useState<string>()
    // Filas del libro
    const [rows, setRows] = useState<Row[]>([])
    // Primera fila seleccionada
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>()
    // Listado de columnas
    const [columns, setColumns] = useState<string[]>([])
    // Posiciones por columna
    const [positions, setPositions] = useState<any>({
        esku: undefined,
        cantidad: undefined,
        total: undefined
    })
    // Respuesta para consolidar en Manager
    const [answer, setAnswer] = useState<any[]>()
    // Documento creado
    const [document, setDocument] = useState({
        Numero: '',
        TipoDocto: ''
    })

    // Respuesta de la API
    useEffect(() => {
        if (data?.status === 'success') {
            setDocument(data.encabezado)
            setStep(5)
        }
    }, [data])

    // Menú superior
    useEffect(() => {
        if (!!exposeSubMenu) {
            // exposeSubMenu([
            //     { key: 'index', name: 'Informes', icon: <SnippetsOutlined /> },
            //     { key: 'emission', name: 'Facturación mensual', icon: <MoneyCollectOutlined /> },
            // ])

            const _submenu = [
                { key: 'index', name: 'Papel de Trabajo', icon: <HomeOutlined /> },
            ]

            if (permissions.includes('FacturacionMensual')) {
                _submenu.push({ key: 'emission', name: 'Facturación mensual', icon: <MoneyCollectOutlined /> },)
            }

            exposeSubMenu(_submenu)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exposeSubMenu])

    // Cargador global del loading
    useEffect(() => {
        setMainLoading(false)
    }, [setMainLoading])

    useEffect(() => {
        if (selectedSheet && file) {
            (async () => {
                const _rows = await readXlsxFile(file, {
                    sheet: selectedSheet
                })

                setRows(_rows)
                setStep(2)
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, selectedSheet])

    useEffect(() => {
        if (sheetNames && sheetNames.length > 0) {
            setDrawer(true)
        }
    }, [sheetNames])

    useEffect(() => {
    
        if (file) {
            setSelectedSheet(undefined)
            console.log('File setted', file);
            // setFileLoading(true)

            (async () => {
                try {
                    const sheets = await readSheetNames(file)
                    setSheetNames(sheets)
                } catch (err) {
                    message.error('El archivo seleccionado no es compatible. Selecciona una planilla Excel .xlsx')
                    setSheetNames([])
                    setFile(undefined)
                }
            })();

            // const _sheetsOptions: SheetOption[] = []
            // for (const sheet of sheets) {
            //     // generate a unique uuid
            //     const uuid = uuidv4()

            //     const rows = await readXlsxFile(file, {
            //         sheet
            //     })

            //     localStorage.setItem(`sheet-${uuid}`, JSON.stringify(rows))
            //     localStorage.setItem(`sheet-${uuid}-length`, `${rows.length}`)

            //     _sheetsOptions.push({
            //         key: `sheet-${uuid}`,
            //         sheet,
            //         length: rows.length
            //     })

            // }

            // console.log({ _sheetsOptions })
        }
    }, [file])

    useEffect(() => {
        if (selectedRowIndex !== undefined) {
            setStep(3)
            setColumns(rows[selectedRowIndex] as string[])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowIndex])

    useEffect(() => {
        if (positions.esku && positions.cantidad && positions.total && selectedRowIndex) {
            const calculated = []
            let i = -1
            for (const row of rows) {
                i++
                if ((i - 1) < selectedRowIndex) {
                    continue
                }
                calculated.push({
                    esku: row[positions.esku] as string,
                    cantidad: row[positions.cantidad] as number,
                    total: Math.round(row[positions.total] as number)
                })
            }

            const consolidated = calculated.reduce((acc: Map<string, any>, currentValue) => {
                /** @todo ¿Qué hacemos con el PLV? */
                if (currentValue.total === 0) {
                    return acc
                }

                const key = `${currentValue.esku}-${currentValue.total}`
                if (acc.has(key)) {
                    const element = acc.get(key)
                    element.cantidad = element.cantidad + currentValue.cantidad
                } else {
                    acc.set(key, currentValue)
                }
                return acc
            }, new Map())

            

            setAnswer(Array.from(consolidated, ([name, value]) => value))
            setStep(4)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positions.esku, positions.cantidad, positions.total])

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        fileList: [],
        action: '',
        customRequest: async ({ file: _file }) => {
            setFile(_file as File)
            return true
        },
        onChange(info) {
            return true
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    }

    return <div className="Emission">
        {/* Drawer de selección de hoja del libro. */}
        <Drawer
            closable={false}
            title="Selección de Hoja"
            placement="right"
            extra={
                <Space>
                    <Button onClick={() => {
                        setDrawer(false)
                        setFile(undefined)
                        setRadioButton(undefined)
                    }}>Cancelar</Button>
                </Space>
            }
            footer={
                <Space direction="vertical" style={{ display: 'inherit' }}>
                    <Button block size="large" disabled={!radioButton} type="primary" onClick={() => {
                        setSelectedSheet(radioButton)
                        setDrawer(false)
                    }}>Seleccionar hoja</Button>
                </Space>
            }
            open={drawer}
        >
            <p>Para comenzar el ingreso de la facturación mensual, es necesario que selecciones una hoja del libro que acabas de subir.</p>
            <p></p>
            <Radio.Group size="large" onChange={e => {
                setRadioButton(e.target.value)
            }}>
                <Space direction="vertical" >
                    {sheetNames.map(sheetName => <Radio key={`Radio-${sheetName}`} value={sheetName}>{sheetName}</Radio>)}
                </Space>
            </Radio.Group>
        </Drawer>

        {/* Pasos laterales */}
        <div className="Emission__Left">
            <Steps
                direction="vertical"
                current={step - 1}
                items={[
                    {
                        title: 'Carga Excel',
                        description: 'Formato Facturación',
                    },
                    {
                        title: 'Primeras filas',
                        description: 'Detección de formato',
                    },
                    {
                        title: 'Selección de Campos',
                        description: 'Parea el documento'
                    },
                    {
                        title: 'Resumen Documento',
                        description: 'Previsualización',
                    },
                    {
                        title: 'Inyección',
                        description: 'en Flexline ERP'
                    },
                ]}
            />
        </div>

        <div className="Emission__Main">
            <Skeleton active loading={drawer}>
                {/* Paso 1: Subir archivo */}

                {step === 1 && <Fragment>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Selecciona o arrastra un <strong>archivo Excel</strong> compatible con el <strong>formato</strong> de facturación mensual
                        </p>
                        <p className="ant-upload-hint">
                            Debes seleccionar un solo archivo. Puedes obtener el formato contactando con un administrador.
                        </p>
                    </Dragger>
                </Fragment>}

                {/* Paso 2: Seleccionar cuál es la primera fila */}
                {step === 2 && <Fragment>
                    <h2>Selección de Primera Fila</h2>
                    <p>El formato parece ser el indicado. Ahora debes seleccionar cuál es la primera fila que contiene los títulos del documento.
                        Recuerda que debe ser la fila que contenta los títulos, tales como <strong>SKU, Unidades, Total Neto</strong>, entre otros.</p>
                    <DynamicTable rows={rows.slice(0, 5)} onChange={setRowSelector} />

                    <Button type='primary' disabled={rowSelector === undefined} size='large' onClick={() => {
                        setSelectedRowIndex(rowSelector)
                        setStep(3)
                    }}>
                        {rowSelector === undefined ? 'Seleccionar fila' : `Seleccionar fila nº ${rowSelector + 1}`}
                    </Button>
                </Fragment>}

                {/* Paso 3: Seleccionar campos de SKU, Cantidad y Precio */}
                {step === 3 && <Fragment>
                    <h2>Selección de Campos</h2>
                    <p>Para generar el documento <strong>ESHOP CHA</strong> que refleja la venta en comercio electrónico, debes seleccionar las columnas que corresponden
                    a los siguientes campos.</p>
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} onFinish={(values) => {
                        setPositions(values)
                        setStep(4)
                    }}>
                        {/* SKU */}
                        <Form.Item
                            help={<>En el formato estándar es la <strong>columna K</strong>, llamada "SKU". Es un campo de entre 5 y 6 caracteres.</>}
                            name="esku"
                            label="eSKU"
                            rules={[{ required: true, message: 'Debes seleccionar una columna que contenga el eSKU.' }]}
                        >
                            <Select
                                placeholder={`Selecciona una de las ${columns.length} columnas disponibles`}
                            >
                                {columns.map((col, i) => <Select.Option value={i}>Col {String.fromCharCode(97 + i).toUpperCase()} | {col}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        {/* Cantidad */}
                        <Form.Item
                            help={<>En el formato estándar es la <strong>columna N</strong>, llamada "Unidades". Es un campo numérico, sin decimales.</>}
                            name="cantidad"
                            label="Unidades"
                            rules={[{ required: true, message: 'Debes seleccionar una columna que contenga las cantidades por orden.' }]}
                        >
                            <Select
                                placeholder={`Selecciona una de las ${columns.length} columnas disponibles`}
                            >
                                {columns.map((col, i) => <Select.Option value={i}>Col {String.fromCharCode(97 + i).toUpperCase()} | {col}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        {/* Precio Total */}
                        <Form.Item
                            help={<>En el formato estándar es la <strong>columna P</strong>, llamada "Total Neto". Es un campo numérico, el cuál será redondeado.</>}
                            name="total"
                            label="Total Neto"
                            rules={[{ required: true, message: 'Debes seleccionar una columna que el valor facturable.' }]}
                        >
                            <Select
                                placeholder={`Selecciona una de las ${columns.length} columnas disponibles`}
                            >
                                {columns.map((col, i) => <Select.Option value={i}>Col {String.fromCharCode(97 + i).toUpperCase()} | {col}</Select.Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item labelAlign="right" wrapperCol={{ span: 24 }} style={{
                            marginTop: 64
                        }}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                Previsualizar
                            </Button>
                        </Form.Item>
                    </Form>
                </Fragment>}

                {/* Paso 4: Previsualización del documento */}
                {step === 4 && <Fragment>
                    <h2>Previsualización del documento</h2>
                    <p>Confirma aquí el detalle del documento que vas a cargar en Flexline ERP. Debido a las aproximaciones, puede que existan diferencias
                        en los montos calculados, producto del redondeo.
                    </p>
                    <Descriptions title="Resumen del documento" bordered>
                        <Descriptions.Item span={3} label="Líneas totales">{answer?.length}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Monto Neto Total">${nf.format(answer?.reduce((acc, i) => {
                            return acc + (i.total * i.cantidad)
                        }, 0) || 0)}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Documentos a facturar">{Math.ceil((answer?.length || 1) / 60)}</Descriptions.Item>
                    </Descriptions>

                    <Button loading={loading} type="primary" size="large" block style={{
                        marginTop: 32
                    }} onClick={async () => {
                        const payload = {
                            answer
                        }
                        
                        const _data = await fetch(payload)
                        console.log({ _data })
                    }}>
                        Inyectar en Flexline ERP
                    </Button>
                </Fragment>}

                {/* Paso 5: Documento creado */}
                {step === 5 && <Fragment>
                    <Result
                        status="success"
                        title={`${document.TipoDocto} ${document.Numero}`}
                        subTitle="Se ha creado el documento indicado."
                        // extra={[
                        //     <Button type="primary" key="console">
                        //         Go Console
                        //     </Button>,
                        //     <Button key="buy">Buy Again</Button>,
                        // ]}
                    />
                </Fragment>}
            </Skeleton>
        </div>
    </div>
}