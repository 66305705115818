import { Form, Skeleton, Tree, TreeDataNode } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Rule } from 'antd/lib/form'
import { SelectValue } from 'antd/lib/select'

import React, { useEffect, useState } from 'react'

import { useApi } from '../../Utils'
import { ClientRazonSocial, Sucursal } from '../../Views/Dashboard'


type SucursalSelectorProps = {
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
    onFocus?: React.FocusEventHandler<HTMLElement> 
    onChange?: ((value: SelectValue, option: any) => void) | undefined
    onBlur?: React.FocusEventHandler<HTMLElement>
    allowClear?: boolean
    disabled?: boolean
    widthSelect?: string
    help?: string
    size: SizeType
    rules?: Rule[]
}

type SucursalEndpoint = {
    razones_sociales: ClientRazonSocial[]
    sucursales: Sucursal[]
}

export function SucursalSelector({ widthSelect, help, rules, ...props}: SucursalSelectorProps) {
    const { data, loading } = useApi<SucursalEndpoint>('post', 'v3/maestros/productos/sucursalesList')
    const [sucursales, setSucursales] = useState<Partial<TreeDataNode>[]>()
    

    useEffect(() => {
        if (data && !loading) {
            setSucursales(data.sucursales.map(suc => {
                const rz = data.razones_sociales.find(rz => rz.id === suc.clientRazonSocialId)

                return {
                    title: <><strong>{rz?.socialName}</strong> - {suc.internalName.toUpperCase()}</>,
                    key: `SUC-${suc.id}`
                }
            }))
            // const treeData = [
            //     {
            //         title: 'Producto activo',
            //         key: 'active'
            //     },
        }
    }, [data, loading])

    if (loading || !sucursales) {
        return <Skeleton />
    }

    return <Form.Item
        label="Disponibilidad en sucursales"
        name="sucursalesAvailability"
        valuePropName="checkedKeys"
        trigger="onCheck"
    >
        <Tree
            checkable
            defaultCheckedKeys={sucursales.map(suc => `${suc.key}`)}
            selectable={false}
            treeData={sucursales}
            />
    </Form.Item>
}