import React, { useState } from 'react'

import { Badge, Menu, Spin } from 'antd';
import { 
    DashboardOutlined,
    CodeSandboxOutlined,
    BankOutlined,
    SlidersOutlined,
    // SettingOutlined,
    UserOutlined,
    LogoutOutlined,
    FileOutlined
} from '@ant-design/icons';

import './index.scss'

import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Store/Actions';

const { SubMenu } = Menu

const AppFrame = ({ children: ChildrenComponent, isMobile }: any) => {
    const { pathname } = useLocation()
    const { userInfo, permissions } = useSelector((state: any) => state)
    const language = userInfo.id_client === '12' ? 'es_ar' : 'es_cl'
    let [, currentPath, currentSubPath] = pathname.split('/')


    const history = useHistory()
    const dispatch = useDispatch()

    const isDark = true

    const requestedPermissions = []
    // Greta: B2B de Órdenes
    if (permissions.includes('Integracion EIT')) {
        requestedPermissions.push({ key: 'orders', name: 'Órdenes', icon: <CodeSandboxOutlined /> })
    }

    // Greta: Integración
    if (permissions.includes('Bodega')
        || permissions.includes('Integracion')
        || permissions.includes('IntegracionWithoutPrice')
        || permissions.includes('Comercial')) {
        requestedPermissions.push({ key: 'warehouse', name: language === 'es_cl' ? 'Bodega' : 'Depósito', icon: <CodeSandboxOutlined /> })
    }
    
    // Greta: Gestion
    if (permissions.includes('Gestion')
     || permissions.includes('Comercial')) {
        requestedPermissions.push({ key: 'accounting', name: 'Gestión', icon: <BankOutlined /> })
        requestedPermissions.push({ key: 'dtes', name: 'Facturas', icon: <FileOutlined /> })
    }

    // Retail: Maestros
    if (permissions.includes('Maestros')) {
        requestedPermissions.push({ key: 'masters', name: 'Maestros', icon: <BankOutlined /> })
    }


    // Retail: Logistica
    if (false) {
        requestedPermissions.push({ key: 'warehouse', name: 'Logística', icon: <CodeSandboxOutlined /> })
    }

    const mainMenu = [
        { key: 'dashboard', name: 'Inicio', icon: <DashboardOutlined /> },
        ...requestedPermissions,
        // { key: 'configuration', name: 'Ajustes', icon: <SettingOutlined /> }
    ]

    const [subMenu, setSubMenu] = useState([])
    const [loading, setLoading] = useState(true)

    const handleMainMenu = ({ key }: any) => {
        if (key.indexOf('user') === -1) {
            history.push(`/${key}`)
        } else {
            // Sub-menu de perfil de usuario
            switch (key){
                case 'user:logout':
                    dispatch(logout(null))
                    return history.push('/')
            }
        }

    }

    const handleSubMenu = ({ key }: any) => {
        key = key === 'index' ? '' : key
        if (key === 'user:logout') {
            dispatch(logout(null))
            return history.push('/')
        } else {
            return history.push(`/${currentPath}/${key}`)
        }        
    }

    currentSubPath = currentSubPath === undefined ? 'index' : currentSubPath

    return (
        <div className={`AppFrame${isMobile ? ' isMobile' : ' isDesktop'}`}>
            <div className={`Header${isDark ? ' Header-dark' : ''}`}>
                <svg className="logo" x="0px" y="0px" width="46px" height="46px" viewBox="0 0 822.68 838.04" overflow="inherit" style={{ float: 'left' }}>
                    <g>
                        <g>
                            <path fillRule="evenodd" fill="rgba(255,255,255,1)" d="M408.9,489.979c0,114.27,92.63,206.89,206.89,206.89 c114.26,0,206.89-92.62,206.89-206.89c0-114.26-92.63-206.88-206.89-206.88C501.53,283.1,408.9,375.719,408.9,489.979z M520.86,492.419c0-53.77,43.59-97.36,97.36-97.36c53.77,0,97.36,43.59,97.36,97.36s-43.59,97.36-97.36,97.36 C564.45,589.779,520.86,546.189,520.86,492.419z"/>
                            <path fillRule="evenodd" fill="rgba(255,255,255,1)" d="M334.45,353.779c57.02-25.46,73.93-72.58,52.52-134.82l-48.18-140.12 c-23-66.88-78.04-93.76-144.93-70.76L53.74,56.27c-3.41,1.17-6.73,2.44-9.93,3.78v99.57l95.52-32.85 c62.97-21.65,111.51,2.05,133.17,65.02l54.2,157.62C327.77,352.52,333.6,350.739,334.45,353.779z"/>
                            <polygon fillRule="evenodd" fill="rgba(255,255,255,1)" points="326.68,349.409 387.04,218.959 485.21,511.1 453.52,700.12 "/>
                        </g>
                        <path fillRule="evenodd" fill="rgba(255,255,255,1)" d="M374.65,473.909c-14.07-40.58,7.42-36.21,48-50.28l209.21-121.23 c40.59-14.08,84.89,7.41,98.97,48l-23.36,325.38l-161.3,55.34c-40.59,14.07-84.89-7.42-98.97-48L374.65,473.909z M618.22,584.909 c53.77,0,97.36-43.59,97.36-97.36s-43.59-97.36-97.36-97.36c-53.77,0-97.36,43.59-97.36,97.36S564.45,584.909,618.22,584.909z"/>
                        <circle fillRule="evenodd" fill="#ccc" cx="75.45" cy="100.549" r="75.45"/>
                        <circle fillRule="evenodd" fill="rgba(255,255,255,0.35)" cx="368.085" cy="740.679" r="97.36"/>
                    </g>
                </svg>
                {isMobile && <>
                <div className="MobileInfo">
                    Bienvenido <strong>{userInfo.name}</strong><br />
                    Estás conectado como <strong>{permissions[0]}</strong>
                </div>
                </>}
                <Menu
                    selectedKeys={[currentPath]}
                    mode={isMobile ? 'inline' : "horizontal"}
                    theme={isDark ? 'dark' : 'light'}
                    onClick={handleMainMenu}
                    className="RootMenu"
                >
                    {mainMenu.map(({ key, icon, name }) => {
                        const isSelected = key === currentPath
                        const localName = isSelected ? name : ''

                        return <Menu.Item key={key}>
                            {icon!}
                            {isMobile ? localName : name}
                        </Menu.Item>
                    })}
                    {!isMobile && <SubMenu title={<><UserOutlined /> {userInfo.firstName}</>} className="UserMenu" popupOffset={[-70,0]}>

    {/* // Administrador
    if (permissions.includes('Administrador')) {
        requestedPermissions.push({ key: 'administrator', name: 'Administrador', icon: <SlidersOutlined />  })
    } */}
                        {permissions.includes('Administrador') && <>
                            <Menu.Item key="administrator">
                                <SlidersOutlined /> Administrador
                            </Menu.Item>
                            {/* <Menu.Divider /> */}
                        </>}
                        <Menu.Item key="user:logout">
                            <LogoutOutlined /> Cerrar Sesión
                        </Menu.Item>
                    </SubMenu>}
                    {/* {!isMobile && <Menu.Item key='user' className="UserMenu">
                        {user.firstName}
                    </Menu.Item>} */}
                </Menu>
                <hr />
                <Menu selectedKeys={[currentSubPath]} mode={isMobile ? "vertical" : "horizontal"} theme={isDark ? 'dark' : 'light'} className="ChildrenMenu" onClick={handleSubMenu}>
                    {subMenu.map(({ key, name, icon, disabled = false, starred = false }) => 
                        <Menu.Item disabled={disabled} key={key}>{icon!}{starred ? <Badge dot={starred}>{name}</Badge> : name}</Menu.Item>
                    )}
                </Menu>
            </div>
            <Spin spinning={loading} delay={500} tip="Obteniendo información..." >
                {React.cloneElement(
                    ChildrenComponent,
                    {
                        exposeSubMenu: setSubMenu,
                        setMainLoading: setLoading
                    }
                )}
            </Spin>
        </div>
    )
}
export default AppFrame