import React, { useRef, useState } from 'react'

import {
    FileExcelFilled,
    FileExcelOutlined
} from '@ant-design/icons';

import {
    Steps,
    Button,
    Row,
    Col,
    Table,
    Empty,
    Tooltip,
    message,
    UploadFile,
    Result
} from 'antd';

import Dragger from 'antd/lib/upload/Dragger';
import { useSelector } from 'react-redux';

import Column from 'antd/lib/table/Column';
import { RcFile } from 'antd/lib/upload';

type AnswerRow = {
    type: string
    answer: {
        inflated: {
            Document: {
                CustomerCode: string
                CustomerName: string
                EmissionDate: string
                ExpectedDate: string
                Number: string
                OutboundTypeCode: string
                ReferenceNumber: string
                Details: {
                    ItemCode: string
                    ItemQty: number
                    LilneCode: number
                    LilneNumber: number
                }[]
            }
        }
        schema: string
        raw: any[]
    }
}

export default function WarehouseInjectorInjWMSTEK ({ rut = '96942690', rsocial = 'GRETA CHILE SpA', documento = '', resetAll }: any) {
    const [step, setStep] = useState(0)
    const { token } = useSelector((state: any) => state)
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [data, setData] = useState<AnswerRow[]>([])
    const [loading, setLoading] = useState(true)
    const [, setDebug] = useState<number>(1)
    const [uploading, setUploading] = useState(false)

    const refUpload = useRef()

    // const props = createUploaderProps('', token, form, '.xlsx', 100, `https://app.bodegaenlinea.cl/api/v3/sync/postUploadLucatmi/${debug}`, 'od', (file) => {
    //     const success = file.response.status === 'success'
    //     const answer = file.response.answer

    //     if (success) {
    //         console.log(success)
    //         setStep(1)
    //         setData(answer)
    //         setLoading(false)
    //     }
    // })

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('od', file as RcFile);
        });
        setUploading(true);
        
        fetch('https://app.bodegaenlinea.cl/api/v3/sync/postUploadLucatmi/0', {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(() => {
                setFileList([]);
                setStep(2)
                message.success('Carga enviada a EIT.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    
    return <Row className="WarehouseInjector--Row">
        <Col span={24}>
            <Steps current={step} items={[
                { title: 'Solicitud', description: 'Detalle en Excel' },
                { title: 'Resumen', description: 'Validación y guardado' },
                { title: 'Transmisión', description: 'Información enviada a EIT' },
            ]} />

            {step === 0 && <div className="WarehouseInjector--Row--Step">
                <Row gutter={16}>
                    <Col xs={24} md={24} style={{
                        gap: 16,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Dragger
                            name={'od'}
                            multiple={false}
                            maxCount={1}
                            action={`https://app.bodegaenlinea.cl/api/v3/sync/postUploadLucatmi/1`}
                            headers={{
                                Authorization: `Bearer ${token}`
                            }}
                            beforeUpload={(file) => {
                                setFileList([file]);
                                return true;
                            }}
                            onChange={(info) => {
                                const { status } = info.file;

                                if (status !== 'uploading') {
                                    // console.log(info.file, info.fileList);
                                }

                                if (status === 'done') {
                                    message.success(`${info.file.name} cargado exitosamente.`);

                                    if (info.file.response.status === 'success') {
                                        const file = info.file
                                        const success = file.response.status === 'success'
                                        const answer = file.response.answer

                                        if (success) {
                                            console.log(success)
                                            setStep(1)
                                            setData(answer)
                                            setLoading(false)
                                        }
                                    }
                                } else if (status === 'error') {
                                    message.error(`${info.file.name} tuvo problemas, revisa el formato e intenta nuevamente.`);
                                }
                            }}
                            // onDrop={(e) => {
                            //     console.log('Dropped files', e.dataTransfer.files);
                            // }}
                            accept=".xlsx"
                            ref={refUpload}
                        >
                            <p className="ant-upload-drag-icon">
                                <FileExcelFilled />
                            </p>
                            <p className="ant-upload-text">Selecciona el archivo para inyectar</p>
                            <p className="ant-upload-hint">
                                En este primer paso, debes cargar archivos exclusivamente de <strong>LUCATMI</strong> para procesar en la inyección a wmsTEK (EIT Logística). <i>Se permite un máximo de 100 item's por documento.</i>
                            </p>
                        </Dragger>

                        <Button icon={<FileExcelOutlined />} target='_blank' type='ghost' block size='large' href='https://app.bodegaenlinea.cl/examples/Ejemplo%20de%20Carga%20Lucatmi.xlsx'>
                            Descargar <strong> Excel </strong> de ejemplo
                        </Button>
                    </Col>
                </Row>
            </div>}

            {step === 1 && <div className="WarehouseInjector--Row--Step">
                <Row gutter={16}>
                    <Col xs={24} md={24} style={{
                        gap: 16,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Table
                            dataSource={data}
                            loading={loading}
                            pagination={false}
                            className='TableListAll'
                            size='small'
                            locale={{
                                emptyText: (
                                    <Empty
                                        style={{ marginTop: '32px', marginBottom: '48px' }}
                                        description='No se han encontrado documentos en el archivo cargado.'
                                    >
                                    </Empty>
                                )
                            }}
                            footer={() => <>Total de documentos {data.length}</>}
                        >
                            <Column
                                title='Numero'
                                key='inflatedDocumentNumber'
                                render={(value, record: AnswerRow) => {
                                    return <Tooltip title={`ID: ${record.type.split(',')}`}>{(record.answer.inflated.Document.Number)}</Tooltip>
                                }}
                            />
                            <Column
                                title='Destinatario'
                                key='inflatedDocumentDestine'
                                render={(value, record: AnswerRow) => {
                                    return record.answer.inflated.Document.CustomerName
                                }}
                            />
                            <Column
                                title='Fecha de Entrega EIT'
                                key='inflatedDocumentCompromiso'
                                render={(value, record: AnswerRow) => {
                                    return record.answer.inflated.Document.ExpectedDate
                                }}
                            />
                            <Column
                                title='Referencias / Productos'
                                key='refProds'
                                render={(value, record: AnswerRow) => {
                                    return `${record.answer.inflated.Document.Details.length} / ${record.answer.inflated.Document.Details.reduce((acc, d) => {
                                        return acc + Number(d.ItemQty)
                                    }, 0)}`
                                }}
                            />
                            
                        </Table>

                        <Button loading={uploading} type='primary' block size='large' onClick={() => {
                            setDebug(0)
                            handleUpload()
                        }}>
                            Transmitir a EIT
                        </Button>
                    </Col>
                </Row>
            </div>}

            {step === 2 && <div className="WarehouseInjector--Row--Step">
                <Result
                    status="success"
                    title="Ordenes transmitidas correctamente"
                    subTitle="Puedes volver a comenzar."
                    extra={[
                        <Button type="primary" key="console" onClick={() => {
                            window.location.reload()
                        }}>
                            Comenzar nuevamente
                        </Button>
                    ]}
                />
            </div>}
            
        </Col>
    </Row>
}