import axios from 'axios';
import { accessToken, serverUrlAR } from '../Constants';
import { useState, useEffect } from 'react';

export type UseApiProps = {
    loading: boolean
    errors: any[]
}

export type ConfigUseApi = {
    body: any
    requiredToken: boolean
    onRequest: boolean
}

export default function useApiAR<T>(type: 'post' | 'get', action = 'custom-query', config = {
    onRequest: false
}) {
    const { onRequest } = config

    const [data, setData] = useState<T | null>(null)
    const [loading, setLoading] = useState(!onRequest)
    const [errors, setErrors] = useState<any[]>([])

    let extra = {
        'access-token': accessToken
    }
    
    const headers = {
        'Content-Type': 'application/json',
        ...extra
    }

    async function start(externalBody: any = undefined) {
        setData(null)
        setErrors([])
        setLoading(true)
        setTimeout(async () => {
            try {
                let response
                if (type === 'post') {
                    response = await axios.post(`${serverUrlAR}${action}`, externalBody, { headers })
                } else {
                    const qS = new URLSearchParams(externalBody).toString();
                    response = await axios.get(`${serverUrlAR}${action}${externalBody ? `?${qS}` : ''}`, { headers })
                }
                if (response.data.status === 'success') {
                    setData(response.data)
                } else {
                    setErrors([{
                        status: response.data.status,
                        statusMessage: response.data.msg,
                        keycode: response.data.error
                    }])
                }
                // @ts-ignore
            } catch (err: any) {
                const localErrors = []
                if (err.message === 'Network Error' || err.response === undefined) {
                    localErrors.push({
                        status: 0,
                        statusMessage: 'Network Error'
                    })
                } else {
                    localErrors.push({
                        status: err.response.status,
                        statusMessage: err?.response?.data?.message || err.response.statusText,
                        keycode: '0'
                    })
                }
                setErrors(localErrors)
            } finally {
                setLoading(false)
            }
        }, 300)
    }

    useEffect(() => {
        if (!onRequest) {
            start()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        data,
        loading,
        errors,
        refetch: start,
        fetch: start
    }
}