import React, { useEffect, useState } from 'react'

import { 
    SnippetsOutlined,
    CodeSandboxOutlined,
    DownloadOutlined
} from '@ant-design/icons';

import {
    PageHeader,
    Table,
    Space,
    Button
} from 'antd';

import './DTE.scss';

import { useApi } from '../../Utils';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import JSZip from 'jszip';
// @ts-ignore
import JSZipUtils from 'jszip-utils';

const dataTypes: string[] = []
// @ts-ignore
dataTypes['33'] = 'Factura'
// @ts-ignore
dataTypes['61'] = 'Nota de crédito'
// @ts-ignore
dataTypes['52'] = 'Guía de despacho'


const DownloadButton = ({ record }: any) => {
    const [loading, setLoading] = useState(false)

    return <Button loading={loading} onClick={() => {
        setLoading(true)
        const oReq = new XMLHttpRequest();
        oReq.open("GET", record.url, true);
        oReq.responseType = "blob";
        oReq.onload = function (oEvent) {
            const blob = oReq.response;
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = `${dataTypes[record.typeDoc]} ${record.name}`.replace(' ', '_');
            a.click();
        }
        oReq.send();
        oReq.onreadystatechange = () => {
            if (oReq.readyState === 4) {
                setLoading(false)
            }
        }
    }} type="ghost" icon={<DownloadOutlined />} size='small'>
        Descargar
    </Button>
}

function DTE({ exposeSubMenu, setMainLoading }: any) {
    const history = useHistory()
    
    const { data, loading, refetch } = useApi<any>('get', 'v3/dtes/list')
    const [facturas, setFacturas] = useState<Array<any>>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
    const [currentShow, setCurrentShow] = useState<any[]>([])

    useEffect(() => {
        if (data && !loading) {
            setFacturas(data.result.map((item: any) => {
                return {
                    ...item,
                    key: item.id
                }
            }))
            setCurrentShow(data.result.map((item: any) => {
                return {
                    ...item,
                    key: item.id
                }
            }))
        }
    }, [data, loading])

    useEffect(() => {
        setMainLoading(loading)
    }, [loading, setMainLoading])

    const getFiles = () => {
        if (selectedRowKeys.length === 0) {
            return
        }
        setMainLoading(true)
        const urls = selectedRowKeys.map((item: any) => {
            return facturas.find((item2: any) => item2.id === item).url
        })
        const ids = selectedRowKeys.map((item: any) => {
            return facturas.find((item2: any) => item2.id === item).name.replace('.pdf', '')
        })
        let count = 0

        const zip = new JSZip()
        urls.forEach((url: any) => {
            JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
                if (err) {
                    throw err
                }
                zip.file(facturas.find(fact => fact.url === url).name, data, { binary: true })
                // zip.file(url.split('/').pop(), data, { binary: true })
                count++
                if (count === urls.length) {
                    zip.generateAsync({ type: 'blob' }).then((content: any) => {
                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(content);
                        a.download = `${ids.join('_')}.zip`;
                        a.click();
                        setTimeout(() => {
                            setMainLoading(false)
                        }, 1000)
                    })
                }
            })
        })
    }


    const columns: ColumnsType<any> = [
    {
        title: 'Tipo',
        dataIndex: 'typeDoc',
        filters: [
        {
            text: 'Factura',
            value: '33',
        },
        {
            text: 'Guía de despacho',
            value: '52',
        },
        {
            text: 'Nota de crédito',
            value: '61',
        }
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value: any, record: any) => record.typeDoc.indexOf(value) === 0,
        render: (value, record) => {
            return <Space size="middle">
                <CodeSandboxOutlined />
                <span>{dataTypes[value]} </span>
                </Space>
        }
    },
    {
        title: 'Número',
        dataIndex: 'name',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => Number(a.name.replace('.pdf', '')) - Number(b.name.replace('.pdf', '')),
        render: (value: string, record) => {
            return <Space size="middle">
                {/* <BarcodeOutlined /> */}
                <span>{value.replace('.pdf', '')}</span>
                </Space>
        }
    },
    {
        title: 'Fecha de Emisión',
        dataIndex: 'created_at',

        render: (value: string, record) => {
            return <Space size="middle">
                {/* <BarcodeOutlined /> */}
                <span>{value}</span>
                </Space>
        }
    },
    {
        title: 'Acciones',
        dataIndex: 'action',
        render: (text: any, record: any) => {
            return (
                <Space size="middle">
                    {/* <a href={record.url}>Descargar</a> */}
                    <DownloadButton record={record} />
                </Space>
            )
        }
    },
    ];

    useEffect(() => {
        console.log({ selectedRowKeys })
    }, [selectedRowKeys])
    
    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Listado de documentos', icon: <SnippetsOutlined /> }
                // { key: 'programming', name: 'Reportes Programados', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        console.log('params', pagination, filters, sorter, extra);
        setCurrentShow(extra.currentDataSource)
    }

    return (
        <div className="App">
            <div className="DTE">
                <PageHeader
                    ghost={true}
                    onBack={() => history.push('/')}
                    title="Listado de documentos"
                    subTitle={`${facturas.length} documento${facturas.length > 1 ? 's' : ''} emitido${facturas.length > 1 ? 's' : ''} las últimas 24 horas`}
                    extra={[
                        <Button key="2" onClick={refetch} type="default">Actualizar documentos</Button>,
                        <Button disabled={!selectedRowKeys.length} key="1" onClick={getFiles} type='primary'>Descargar seleccionados {selectedRowKeys.length > 0 ? `(${selectedRowKeys.length})` : ''}</Button>,
                    ]}
                    />
                    
                <div className="Wrapper">
                    <Table
                        columns={columns}
                        dataSource={facturas}
                        size='small'
                        sticky
                        pagination={false}
                        footer={() => `Estás viendo un total de ${currentShow.length}/${facturas.length} documento${currentShow.length !== 1 ? 's' : ''}.`}
                        rowSelection={{
                            onChange: (selectedRowKeys: React.Key[]) => {
                                setSelectedRowKeys(selectedRowKeys)  
                            },
                            selectedRowKeys
                        }}
                        onChange={onChange} />
                </div>
            </div>
        </div>
    )
}

export default DTE