import React, { useState } from 'react'
import {
    Select,
    Form,
    Button
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { serverUrl } from '../../Constants'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportCostOfSale() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const { Option } = Select

    /**
     * @todo Get from API.
     */
    const yearStart = 2019
    const yearEnd = new Date().getFullYear()
    const years = []
    for (let i = yearStart; i <= yearEnd; i++) {
        years.push(i)
    }

    const allPeriodosAvailable = [
        // Años completos
        ...years.map(y => ({ value: `${y}`, label: `Año Comercial ${y}` })),
        ...years.reduce<any>((acc, y) => {
            const months = [
                { value: `${y}01`, label: `Enero ${y}` },
                { value: `${y}02`, label: `Febrero ${y}` },
                { value: `${y}03`, label: `Marzo ${y}` },
                { value: `${y}04`, label: `Abril ${y}` },
                { value: `${y}05`, label: `Mayo ${y}` },
                { value: `${y}06`, label: `Junio ${y}` },
                { value: `${y}07`, label: `Julio ${y}` },
                { value: `${y}08`, label: `Agosto ${y}` },
                { value: `${y}09`, label: `Septiembre ${y}` },
                { value: `${y}10`, label: `Octubre ${y}` },
                { value: `${y}11`, label: `Noviembre ${y}` },
                { value: `${y}12`, label: `Diciembre ${y}` }
            ]

            for (const month of months) {
                const monthId = months.findIndex((m: any) => m.value === month.value)
                const currentMonth = new Date().getMonth()

                if (monthId && y === new Date().getFullYear() && monthId > currentMonth) {
                    break
                } else {
                    acc.push(month)
                }
            }
            return acc
        }, []),
    ]

    const onFinish = (data: any) => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/costOfSale`,
            responseType: 'blob',
            data
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Costo de Ventas ' + data.periodo + '.xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
              window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' Costo de Ventas ' + data.periodo + '.xlsx');
            }
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        periodo: `${new Date().getFullYear()}${pad((new Date().getMonth() + 1), 2)}`
    }}>
        <p>Puedes solicitar el informe sobre el compras registradas en un periodo determinado.</p>
        <br />
        <Form.Item name="periodo" label="Periodo">
            <Select
                disabled={loading}
            >
            {allPeriodosAvailable.map(field => (
                <Option value={field.value} key={field.value}>{field.label}</Option>
            ))}
            </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Descargar Informe
        </Button>
    </Form>
}
