import React, { useEffect } from 'react'

import { Result } from 'antd';
// import { useHistory } from 'react-router';

import './Warehouse.scss'
import { useSelector } from 'react-redux';
import calculateMenu from './calculateMenu';
// Store
// import { useSelector } from 'react-redux'

function Warehouse({ isMobile, exposeSubMenu, setMainLoading }: any) {
    // const history = useHistory()
    // const { token } = useSelector((state: any) => state)
    const { permissions, userInfo: { id_client } } = useSelector((state: any) => state)

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu(calculateMenu(permissions, id_client))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, exposeSubMenu])

    useEffect(() => {
        setMainLoading(false)
    }, [setMainLoading])

    return (
        <div className="App">
            <div className="Warehouse">
                <div className="Wrapper">
                    <Result
                        status="403"
                        title={<>Módulo de <strong>Logística</strong></>}
                        subTitle={<>
                        En esta sección podrás configurar los elementos básicos del sistema, como el catálogo de productos y los documentos transaccionales disponibles para operar.
                        </>}
                    />
                </div>
            </div>
        </div>
    )
}

export default Warehouse