import React, { useState, useEffect } from 'react'

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

import {
  Button,
  Checkbox,
  Empty,
  Form,
  Input,
  Space,
  Table
} from 'antd'

import { useApi } from '../../../Utils'

import { LaravelEntity } from '../../Dashboard'

const { Column } = Table

export interface Colaborador extends LaravelEntity {
  RUT?: string // "12.345.678-9"
  address: string // "PJE SUBERCASEAUX 1970, VILLA EL PARQUE"
  birthdayAt: string // "1967-10-23"
  city: string // "LA SERENA"
  clientId: number // "4"
  commune: string // "LA SERENA"
  firstName: string // "CRISTIAN"
  identityNumber: number // "10422125"
  lastName: string // "CANALES"
  mailAddress: string // "abnerpardo67@gmail.com"
  roleId: number // "1"
  secondName: string // "ABNER"
  surName: string // "PARDO"
  // id: 1
  // created_at: "2022-10-30 21:33:50"
  // deleted_at: null
}

export default function CollaboratorAll() {
  const { data, loading, refetch } = useApi<any>(
    'post',
    'v3/maestros/colaboradores/list'
  )
  const [dataSource, setDataSource] = useState<Colaborador[]>([])

  const {
    data: toggleData,
    loading: toggleLoading,
    // fetch: toggleFetch
  } = useApi<any>('post', 'v3/maestros/colaboradores/toggle', {
    requiredToken: true,
    onRequest: true
  })

  const [refetchParams, setRefetchParams] = useState<any>(null)
  const [form] = Form.useForm()
  // const history = useHistory()

  useEffect(() => {
    if (data?.data?.records?.data) {
      setDataSource(data.data.records.data)
    }
  }, [data])

  useEffect(() => {
    console.log(toggleData)
    if (toggleData?.status === 'success') {
        refetch(refetchParams)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleData])

  useEffect(() => {
    if (refetchParams) {
      refetch(refetchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchParams])

  const initialValues = {
    // hasVat: true,
    hasInternalControl: null,
    // hasStockControl: true,
    enabled: true,
    search: '',
    categoryId: null
  }

  return (
    <Space size='middle' direction='vertical'>
      <Form
        initialValues={initialValues}
        layout={'inline'}
        form={form}
        onFinish={(values) => {
          if (JSON.stringify(values) !== JSON.stringify(refetchParams)) {
            setRefetchParams(values)
          }
        }}
      >
        <Form.Item label='Nombre o RUT' name='search'>
          <Input
            placeholder='Ingresa el nombre del colaborador o su RUT...'
            disabled={loading}
            allowClear={true}
            onBlur={() => form.submit()}
            onKeyDown={(event) =>
              event.key === 'Enter' ? form.submit() : null
            }
            size={undefined}
            // style={{ width: '250px' }}
          />
        </Form.Item>
        {/* <CategorySelector
          disabled={loading}
          allowClear={true}
          onChange={() => form.submit()}
          size={undefined}
          // widthSelect={'calc(20vw)'}
        /> */}
        {/* <Form.Item
          name='isChief'
          valuePropName='checked'
          className='CheckedClass'
        >
          <Checkbox
            indeterminate={form.getFieldValue('isChief') === null}
            onChange={() => form.submit()}
          >
            Rol de Encargado
          </Checkbox>
        </Form.Item> */}
        <Form.Item
          name='enabled'
          valuePropName='checked'
          className='CheckedClass'
        >
          <Checkbox onChange={() => form.submit()}>Vigente</Checkbox>
        </Form.Item>
      </Form>

      <Table
        dataSource={dataSource}
        loading={loading || toggleLoading}
        pagination={{ ...data?.data?.pagination, position: ['bottomRight'] }} // 'topRight',
        onChange={(pagination, filters, sorter, extra) => {
          refetch({
            page: pagination.current,
            ...refetchParams
          })
        }}
        className='TableListAll'
        size='small'
        locale={{
          emptyText: (
            <Empty
              style={{ marginTop: '32px', marginBottom: '48px' }}
              description='No se han encontrado colaboradores con los criterios de bùsqueda.'
            >
              <Button
                ghost
                type='primary'
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setTimeout(() => form.submit(), 100)
                }}
              >
                Reestablecer filtros
              </Button>
            </Empty>
          )
        }}
        footer={() => <>Total de colaboradores {data?.data?.pagination?.total}</>}
      >
        <Column
          title='RUT'
          dataIndex='RUT'
          key='RUT'
          // render={(value, record: any) => {
          //   return (
          //     <span style={{ lineHeight: '10px' }}>
          //       <strong>{value}</strong>
          //       <br />
          //       <small>
          //         ({record.cod_interno}) -{' '}
          //         {description.get(Number(record.typeProduct))}
          //       </small>
          //     </span>
          //   )
          // }}
        />
        <Column
          title='Nombre Completo'
          dataIndex='fullName'
          key='fullName'
          render={(value, record: Colaborador) => {
            return <>{`${record.lastName} ${record.surName}`.trim()}, {`${record.firstName} ${record.secondName}`.trim()}</>
          }}
        />
        <Column
          title='Comuna'
          dataIndex='commune'
          key='commune'
          // render={(value, record: Colaborador) => {
          //   if (record.roleId === 1) {
          //     return <Tag color='green'>Encargado</Tag>
          //   } else if (record.roleId === 2) {
          //     return <Tag color='blue'>Vendedor</Tag>
          //   } else {
          //     return <Tag color='red'>Desconocido</Tag>
          //   }
          // }}
        />
        <Column
          title='Encargado'
          align="center"
          dataIndex='isChief'
          key='isChief'
          render={(value, record: Colaborador) => record.roleId > 1 ? <CheckCircleFilled style={{
              color: "#1890ff"
          }} /> : <CloseCircleFilled style={{
              color: "#00000040"
          }} />}
        />
        <Column
          title='Administrador'
          align="center"
          dataIndex='isAdmin'
          key='isAdmin'
          render={(value, record: Colaborador) => record.roleId > 2 ? <CheckCircleFilled style={{
              color: "#1890ff"
          }} /> : <CloseCircleFilled style={{
              color: "#00000040"
          }} />}
        />
        {/* <Column title="Control Stock" dataIndex="hasStockControl" key="hasStockControl" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        
        {/* <Column title="Mìnimo" dataIndex="minStock" key="minStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="Máximo" dataIndex="maxStock" key="maxStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="IVA" dataIndex="hasVat" key="hasVat" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        {/* <Column title="Caducidad" dataIndex="hasExpires" key="hasExpires" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}

        <Column
          title='Opciones'
          key='action'
          render={(text, record: any) => (
            <Space size='middle'>
              {/* <Button
                onClick={() => {
                  history.push(`/masters/collaborators/edit/${record.id}`)
                }}
                loading={toggleLoading}
                size='small'
                type='primary'
                ghost
              >
                Editar
              </Button>
              <Popconfirm
                title={`¿Estás seguro de eliminar al colaborador ${`${record.lastName} ${record.surName}`.trim()}, ${`${record.firstName} ${record.secondName}`.trim()}?`}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                placement='left'
                onConfirm={() => {
                  // delete({
                  //   productId: record.id
                  // })
                }}
                onCancel={() => {
                  //
                }}
                okType={'danger'}
                okText='Si'
                cancelText='No'
              >
                <Button
                  size='small'
                  type={'ghost'}
                  danger
                >
                  Eliminar
                </Button>
              </Popconfirm> */}
            </Space>
          )}
        />
      </Table>
    </Space>
  )
}
