import React, { useState } from 'react'
import {
    Form,
    Button
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { serverUrl } from '../../Constants'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportPendingInvoice() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    
    const onFinish = (data: any) => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/pendings`,
            responseType: 'blob',
            data
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Documentos sin facturar.xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
              window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' Documentos sin facturar.xlsx');
            }
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        periodo: `${new Date().getFullYear()}${pad(new Date().getMonth() + 1, 2)}`
    }}>
        <p>Puedes solicitar el informe sobre todos los documentos pendientes de facturar en el sistema.</p>
        <br />
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Descargar Informe
        </Button>
    </Form>
}
