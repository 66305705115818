import React from 'react';

import {
    HomeOutlined,
    CloudUploadOutlined,
    FileSyncOutlined,
    ScanOutlined,
    
    FieldTimeOutlined
} from '@ant-design/icons';

import { MenuInstance } from '../types';
import { Badge } from 'antd';

const CLIENT_GRETA_SA_AR = 12

export default function calculateMenu(permissions: string[], id_client?: string) {
    const clientId = Number(id_client) || 0

    const menues = [
        { key: 'index', name: 'Estadísticas', icon: <HomeOutlined /> }
    ] as MenuInstance[]

    /**
     * Permisos para ARGENTINA
     * @see IntegrationWMSTEKAR
     */
    if ([CLIENT_GRETA_SA_AR].includes(clientId)) {
        menues.push({
            key: 'sync-bay', name: 'Sincronizador', icon: <Badge dot={false} offset={[100, 0]}><FileSyncOutlined /></Badge>
        })

        /**
         * Los mostramos para ofrecer servicios futuros.
         */
        menues.push({ key: 'orderControl', disabled: true, name: 'Control de Pedidos', icon: <ScanOutlined /> })
        menues.push({ key: 'realTime', disabled: true, name: 'Picking en Tiempo Real', icon: <FieldTimeOutlined /> })
    }

    /**
     * Permisos de Inyectadores
     * @see Injector
     */
    if (permissions.includes('Injector')
        || permissions.includes('Comercial')) {
        menues.push({
            key: 'injector', name: 'Inyectador', icon: <CloudUploadOutlined />
        })
        /**
         * @todo Los códigos equivalentes no están en uso hasta 
         * desarrollar un actualizador de los mismos.
         */
        // menues.push({
        //     key: 'alternate-codes', name: 'Códigos Equivalentes', icon: <BarcodeOutlined />
        // })

        /**
         * @todo Las funciones de control de pedidos y picking en tiempo real
         * no han sido migradas desde la versión anterior de BOL.
         */
        // menues.push({ key: 'orderControl', disabled: true, name: 'Control de Pedidos', icon: <ScanOutlined /> })
        // menues.push({ key: 'realTime', disabled: true, name: 'Picking en Tiempo Real', icon: <FieldTimeOutlined /> })

        /**
         * @todo La función de inventario no ha sido migrada desde la versión anterior
         * de BOL, debe implementarse en la App Móvil y versión web.
         */
        // menues.push({ key: 'inventory', disabled: true, name: 'Inventarios', icon: <UsergroupAddOutlined /> })
    }

    /**
     * Permisos de Integración wmsTEK
     * @see IntegrationWMSTEK
     */
    if (permissions.includes('IntegrationWMSTEK')) {
        menues.push({
            key: 'dispatch-bay', name: 'Sincronizador', icon: <Badge dot={false} offset={[100, 0]}><FileSyncOutlined /></Badge>
        })
    }

    return menues
}