export const IN_CLFALABELLA = 1;
export const IN_CLRIPLEY = 5;
export const IN_CLHITES = 2;
export const IN_CLLAPOLAR = 3;
export const IN_CLCENCO = 4;
export const IN_CLMAYORISTAS = 6;
export const IN_PEFALABELLA = 7;
export const IN_PERIPLEY = 8;
export const IN_PEOESCHLE = 9;
export const IN_CLML = 10;
export const IN_CLMINORISTAS = 11;
export const IN_CLSOLICITUDCONSUMO = 12;
export const IN_CLLUCATMI = 13;
export const IN_CLFIMPORT = 14;
export const IN_CLTRASPASO = 15;