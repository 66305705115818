import React from 'react'
import { Helmet } from 'react-helmet'

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter, Switch } from 'react-router-dom'
import './App.scss'

// Vistas
import {
  Login,
  DashboardIndex,
  WarehouseIndex,
  AccountingIndex,
  ConfigurationIndex,
  MastersIndex,
  DTEIndex,
  OrdersIndex,
  GetOrderIndex,
  OrderEmissionFactIndex
} from './Views'

// Router
import { PublicRoute, PrivateRoute } from './Routes'
import AccountingProgramming from './Views/Accounting/Programming'
import MastersProducts from './Views/Masters/MastersProducts'
import MastersProductsForm from './Views/Masters/MastersProductsForm'
import MastersCollaborators from './Views/Masters/MastersCollaborators'
import MastersCollaboratorsForm from './Views/Masters/MastersCollaboratorsForm'
import WarehouseInjector from './Views/Warehouse/WarehouseInjector'
import WarehouseCodeAlias from './Views/Warehouse/WarehouseCodeAlias'
import WarehouseSync from './Views/Warehouse/WarehouseSync'
import AdministratorIndex from './Views/Administrator/index'
import WarehouseSyncAR from './Views/Warehouse/WarehouseSyncAR'
// import firebase from 'firebase'

const { persistor, store } = configureStore()

function App() {
  // https://www.freecodecamp.org/news/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1/
  /* const messaging = firebase.messaging()
  messaging.requestPermission().then(() => {
    messaging.getToken().then(token => {
      console.log({ token })
    })
  })
  */
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Helmet titleTemplate="Bodega en Línea - %s" />
          <Switch>
            <PublicRoute restricted={true} component={Login} path="/" exact />
            <PrivateRoute component={Login} path="/secret" exact />
            <PrivateRoute component={DashboardIndex} path="/dashboard" exact />
            <PrivateRoute component={MastersIndex} path="/masters" exact />
            <PrivateRoute component={MastersProducts} path="/masters/products" exact />
            <PrivateRoute component={MastersProductsForm} path="/masters/products/create" exact />
            <PrivateRoute component={MastersProductsForm} path="/masters/products/edit/:productId" />
            <PrivateRoute component={MastersCollaborators} path="/masters/collaborators" exact />
            <PrivateRoute component={MastersCollaboratorsForm} path="/masters/collaborators/create" exact />
            <PrivateRoute component={WarehouseIndex} path="/warehouse" exact />
            <PrivateRoute component={WarehouseInjector} path="/warehouse/injector" exact />
            <PrivateRoute component={WarehouseCodeAlias} path="/warehouse/alternate-codes" exact />
            <PrivateRoute component={WarehouseSync} path="/warehouse/dispatch-bay" exact />
            <PrivateRoute component={WarehouseSyncAR} path="/warehouse/sync-bay" exact />
            <PrivateRoute component={OrdersIndex} path="/orders" exact />
            <PrivateRoute component={OrderEmissionFactIndex} path="/orders/emission" exact />
            <PrivateRoute component={GetOrderIndex} path="/orders/:orderId" exact />
            <PrivateRoute component={AccountingIndex} path="/accounting" exact />
            <PrivateRoute component={DTEIndex} path="/dtes" exact />
            <PrivateRoute component={AccountingProgramming} path="/accounting/programming" exact />
            <PrivateRoute component={ConfigurationIndex} path="/configuration" exact />
            <PrivateRoute component={AdministratorIndex} path="/administrator" exact />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App