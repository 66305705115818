import {
    LOGIN_USER, LOGOUT
} from "../../Constants"

const initialState: any = {
    token: null,
    isLogged: false,
    serverUrl: 'https://app.bodegaenlinea.cl/api/',
    userInfo: {},
    permissions: []
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOGIN_USER:
            return updateState(state, {
                ...action.payload    
            });
        case LOGOUT:
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
    }
    return state
}

export default rootReducer