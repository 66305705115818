import React, { useState } from 'react'
import {
    Select,
    Form,
    Button
} from 'antd'

import { 
    DownloadOutlined
} from '@ant-design/icons'

import axios from 'axios'
import { serverUrl } from '../../Constants'

export const pad = (n: any, width: any, z = '0') => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default function ReportFolderExchange() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const { Option } = Select

    /**
     * @todo Get from API.
     */
    const allYearsAvailable = [
        { value: '2019', label: '2019'},
        { value: '2020', label: '2020'},
        { value: '2021', label: '2021' },
        { value: '2022', label: '2022' },
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
    ]

    const onFinish = (data: any) => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${serverUrl}v3/gestion/exchanges`,
            responseType: 'blob',
            data
          }).then((response) => {
            let stringDate = "" + new Date().getFullYear() + pad(new Date().getMonth() + 1, 2) + pad(new Date().getDate(), 2);
            if (!window.navigator.msSaveOrOpenBlob){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', stringDate + ' Carpetas de Importacion.xlsx');
              document.body.appendChild(link);
              link.click();
            } else {
              window.navigator.msSaveOrOpenBlob(new Blob([response.data]), stringDate + ' Carpetas de Importacion.xlsx');
            }
            setLoading(false)
          })
    }

    return <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish} initialValues={{
        year: `${new Date().getFullYear()}`
    }}>
        <p>Puedes solicitar el informe sobre las carpetas de importacion ingresadas, recepcionadas y valorizadas.</p>
        <br />
        <Form.Item name="year" label="Año">
            <Select
                disabled={loading}
            >
            {allYearsAvailable.map(yearAv => (
                <Option value={yearAv.value} key={yearAv.value}>{yearAv.label}</Option>
            ))}
            </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<DownloadOutlined />} className='Accounting--DownloadBtn' >
          Descargar Informe
        </Button>
    </Form>
}
