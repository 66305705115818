import React, { useEffect } from 'react'

import { 
    SnippetsOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';

// Store
// import { useSelector } from 'react-redux'

function AccountingProgramming({ isMobile, exposeSubMenu }: any) {
    // const { token } = useSelector((state: any) => state)

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Informes', icon: <SnippetsOutlined /> },
                { key: 'programming', name: 'Envíos', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    return (
        <div className="App">
            <div className="AccountingProgramming">
                <div className="Wrapper">
                    {isMobile ? 'Mobile' : 'Desktop'} - AccountingProgramming index.
                </div>
            </div>
        </div>
    )
}

export default AccountingProgramming