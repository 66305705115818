import React, { useEffect, useState } from 'react'

import { 
    SnippetsOutlined,
    CheckSquareOutlined} from '@ant-design/icons';

import {
    PageHeader,
    Table,
    Space} from 'antd';


import { useApi } from '../../Utils';
import { ColumnsType } from 'antd/lib/table';
import { Link, useHistory } from 'react-router-dom';

import './Administrator.scss';

const dataTypes: string[] = []
// @ts-ignore
dataTypes['33'] = 'Factura'
// @ts-ignore
dataTypes['61'] = 'Nota de crédito'
// @ts-ignore
dataTypes['52'] = 'Guía de despacho'


function Administrator({ exposeSubMenu, setMainLoading }: any) {
    const history = useHistory()
    
    const { data, loading } = useApi<any>('get', 'v3/administrator/users/list')
    const [facturas, setFacturas] = useState<Array<any>>([])
    const [selectedRowKeys, ] = useState<any>([])
    const [,setCurrentShow] = useState<any[]>([])

    useEffect(() => {
        if (data && !loading) {
            setFacturas(data.result.map((item: any) => {
                return {
                    ...item,
                    key: item.id
                }
            }))
            setCurrentShow(data.result.map((item: any) => {
                return {
                    ...item,
                    key: item.id
                }
            }))
        }
    }, [data, loading])

    useEffect(() => {
        setMainLoading(loading)
    }, [loading, setMainLoading])



    const columns: ColumnsType<any> = [
    // {
    //     title: 'Tipo',
    //     dataIndex: 'typeDoc',
    //     filters: [
    //     {
    //         text: 'Factura',
    //         value: '33',
    //     },
    //     {
    //         text: 'Guía de despacho',
    //         value: '52',
    //     },
    //     {
    //         text: 'Nota de crédito',
    //         value: '61',
    //     }
    //     ],
    //     // specify the condition of filtering result
    //     // here is that finding the name started with `value`
    //     onFilter: (value: any, record: any) => record.typeDoc.indexOf(value) === 0,
    //     render: (value, record) => {
    //         return <Space size="middle">
    //             <CodeSandboxOutlined />
    //             <span>{dataTypes[value]} </span>
    //             </Space>
    //     }
    // },
    {
        title: 'Nombre completo',
        dataIndex: 'name',
        defaultSortOrder: 'descend',
        sorter: true,
        render: (value: string) => {
            return <Space size="middle">
                {/* <BarcodeOutlined /> */}
                <span>{value}</span>
                </Space>
        }
    },
    {
        title: 'Correo electrónico',
        dataIndex: 'email',
        defaultSortOrder: 'descend',
        sorter: true,
        render: (value: string) => {
            return <Space size="middle">
                {/* <BarcodeOutlined /> */}
                <span>{value}</span>
                </Space>
        }
    },
    {
        title: 'Fecha de Registro',
        dataIndex: 'created_at',

        render: (value: string) => {
            return <Space size="middle">
                {/* <BarcodeOutlined /> */}
                <span>{value}</span>
                </Space>
        },
        // width: 200
    },
    {
        title: 'Acciones',
        dataIndex: 'action',
        render: (text: any, record: any) => {
            return (
                <Space size="small">
                    {/* <Button icon={<KeyOutlined />}>Contraseña</Button> */}
                    <Link to={`/administrator/user/permissions/${record.id}`}><CheckSquareOutlined /> Permisos</Link>
                    {/* <Button icon={<CloseOutlined />}>Deshabilitar</Button> */}
                </Space>
            )
        }
    },
    ];

    useEffect(() => {
        console.log({ selectedRowKeys })
    }, [selectedRowKeys])
    
    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Listado de documentos', icon: <SnippetsOutlined /> }
                // { key: 'programming', name: 'Reportes Programados', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    function onChange(pagination: any, filters: any, sorter: any, extra: any) {
        console.log('params', pagination, filters, sorter, extra);
        setCurrentShow(extra.currentDataSource)
    }

    return (
        <div className="App">
            <div className="DTE">
                <PageHeader
                    ghost={true}
                    onBack={() => history.push('/')}
                    title="Listado de Usuarios"
                    subTitle={`${facturas.length} documento${facturas.length > 1 ? 's' : ''} emitido${facturas.length > 1 ? 's' : ''} las últimas 24 horas`}
                    // extra={[
                    //     <Button key="2" onClick={refetch} type="default">Actualizar documentos</Button>,
                    //     <Button disabled={!selectedRowKeys.length} key="1" onClick={getFiles} type='primary'>Descargar seleccionados {selectedRowKeys.length > 0 ? `(${selectedRowKeys.length})` : ''}</Button>,
                    // ]}
                    />
                    
                <div className="Wrapper">
                    <Table
                        columns={columns}
                        dataSource={facturas}
                        size='small'
                        sticky
                        pagination={false}
                        // footer={() => `Estás viendo un total de ${currentShow.length}/${facturas.length} documento${currentShow.length !== 1 ? 's' : ''}.`}
                        // rowSelection={{
                        //     onChange: (selectedRowKeys: React.Key[]) => {
                        //         setSelectedRowKeys(selectedRowKeys)  
                        //     },
                        //     selectedRowKeys
                        // }}
                        onChange={onChange} />
                </div>
            </div>
        </div>
    )
}

export default Administrator