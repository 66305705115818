import React, { useEffect, useState } from 'react'

import { 
    HomeOutlined,
    BarcodeOutlined,
    // FileTextOutlined,
    // BoxPlotOutlined,
    UserOutlined,
    AppstoreAddOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

import {
    Button,
    Form,
    Menu
} from 'antd';

import './Masters.scss'

import {
    ProductCreate,
} from './Products/';
import { useHistory } from 'react-router-dom';

// Store
// import { useSelector } from 'react-redux'

export interface MastersProductsParams {
    productId?: string
}

export const parseParams = (params: MastersProductsParams): {
    productId: number | null
} => {
    return {
        productId: params.productId ? parseInt(params.productId) : null
    }
}

export default function MastersProducts({ exposeSubMenu, setMainLoading }: any) {
    // const { token } = useSelector((state: any) => state)
    const [selectedMenu, setSelectedMenu] = useState<string>('create')
    const [waiting, setWaiting] = useState<number>(0)

    const [form] = Form.useForm()
    const history = useHistory()

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Resumen', icon: <HomeOutlined /> },
                { key: 'products', name: 'Productos', icon: <BarcodeOutlined /> },
                { key: 'collaborators', name: 'Colaboradores', icon: <UserOutlined /> },
                // { key: 'categories', name: 'Categorìas', icon: <BoxPlotOutlined /> },
                // { key: 'documents', name: 'Documentos', icon: <FileTextOutlined /> },
            ])
        }
    }, [exposeSubMenu])

    useEffect(() => {
        setMainLoading(false)
    }, [setMainLoading])

    const options = [
        { key: 'all', name: 'Listar todos los productos', icon: <UnorderedListOutlined /> },
        { key: 'create', name: 'Crear nuevo producto', icon: <AppstoreAddOutlined /> }
    ]

    const Wrapper = new Map([
        ['all', {
            url: '/masters/products',
            component: null,
            menu: null
        }],
        ['create', {
            url: null,
            component: <ProductCreate form={form} setSelectedMenu={setSelectedMenu} waiting={waiting} setWaiting={setWaiting} setMainLoading={setMainLoading} />,
            menu: <>
                <Button className="MasterSubMenuExtras" danger type='primary' loading={waiting > 0} onClick={() => {
                    form.submit()
                }}>Guardar</Button>
            </>
        }],
    ])

    return (
        <div className="App">
            <div className="MastersProducts">
                <Menu selectedKeys={[selectedMenu]} mode={"horizontal"} theme={'light'} className="MasterSubMenu" onClick={({ key }: any) => {
                    if (Wrapper.has(key)) {
                        const url = Wrapper.get(key)?.url
                        
                        if (url) {
                            console.log(`Going to ${url}`)
                            history.push(url)
                        } else {
                            setSelectedMenu(key)
                        }
                    }
                }}>
                    {options.map(({ key, name, icon }) => 
                        <Menu.Item key={key}>{icon!}{name}</Menu.Item>
                    )}
                    
                    {Wrapper.get(selectedMenu)?.menu}                    
                </Menu>
                <div className={`Wrapper Sub-${selectedMenu}`}>
                    {Wrapper.get(selectedMenu)?.component}
                </div>
            </div>
        </div>
    )
}
