import React, { useEffect, useState } from 'react'

import {
    List,
    Empty,
    PageHeader,
    Row,
    Col} from 'antd';

import {
    LogoFalabella,
    LogoHites,
    LogoLaPolar,
    LogoParis,
    LogoRipley,
    LogoOeschle,
    LogoML,
    LogoSolicitudConsumo,
    LogoLucatmi
} from '../../Assets';
import { useSelector } from 'react-redux';

import {
    WarehouseInjectorMercadoLibre,
    WarehouseInjectorFalabella,
    WarehouseInjectorRipley,
    WarehouseInjectorOeschle,
    WarehouseInjectorDocuments,
    WarehouseInjectorInjWMSTEK,
    WarehouseInjectorInvoice
} from './Elements';

import {
    IN_CLML,
    IN_CLFALABELLA,
    IN_CLRIPLEY,
    IN_CLHITES,
    IN_CLLAPOLAR,
    IN_CLCENCO,
    IN_CLMAYORISTAS,
    IN_PEFALABELLA,
    IN_PEOESCHLE,
    IN_PERIPLEY,
    IN_CLSOLICITUDCONSUMO,
    IN_CLLUCATMI,
    IN_CLFIMPORT,
    IN_CLTRASPASO
} from './constants';

import './Warehouse.scss'
import calculateMenu from './calculateMenu';


export default function WarehouseInjector({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const { userInfo } = useSelector((state: any) => state)
    const [active, setActive] = useState<number>(0)

    useEffect(() => {
        setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const data = [
        {
            id: IN_CLML,
            title: 'MERCADO LIBRE CHILE LIMITADA',
            description: '77.398.220-1',
            icon: <img src={LogoML} alt="LogoML" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLFALABELLA,
            title: 'FALABELLA RETAIL S.A.',
            description: '77.261.280-K',
            icon: <img src={LogoFalabella} alt="LogoFalabella" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLRIPLEY,
            title: 'COMERCIAL ECCSA S.A.',
            description: '83.382.700-6',
            icon: <img src={LogoRipley} alt="LogoRipley" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLHITES,
            title: 'HITES S.A.',
            description: '81.675.600-6',
            icon: <img src={LogoHites} alt="LogoHites" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLLAPOLAR,
            title: 'EMPRESAS LA POLAR S.A.',
            description: '96.874.030-K',
            icon: <img src={LogoLaPolar} alt="LogoLaPolar" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLCENCO,
            title: 'CENCOSUD RETAIL S.A.',
            description: '81.201.000-K',
            icon: <img src={LogoParis} alt="LogoParis" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion']
        },
        {
            id: IN_CLLUCATMI,
            title: 'Lucatmi',
            description: <>Envío de pedidos a wmsTEK</>,
            icon: <img src={LogoLucatmi} alt="LogoLucatmi" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion', 'IntegracionEIT']
        },
        {
            id: IN_CLSOLICITUDCONSUMO,
            title: 'Solicitud de Consumo',
            description: <><strong>Documento: </strong>SOLICITUD INY</>,
            icon: <img src={LogoSolicitudConsumo} alt="LogoSolicitudConsumo" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion', 'IntegracionWithoutPrice']
        },
        {
            id: IN_PEFALABELLA,
            title: 'SAGA FALABELLA S.A.',
            description: '20100128056',
            icon: <img src={LogoFalabella} alt="LogoFalabella" />,
            state: useState(false),
            disabled: false,
            clientId: 11,
            permission: ['Integracion']
        },
        {
            id: IN_PERIPLEY,
            title: 'TIENDAS POR DEPARTAMENTO RIPLEY S.A.C.',
            description: '20337564373',
            icon: <img src={LogoRipley} alt="LogoRipley" />,
            state: useState(false),
            disabled: false,
            clientId: 11,
            permission: ['Integracion']
        },
        {
            id: IN_PEOESCHLE,
            title: 'TIENDAS PERUANAS S.A.',
            description: '20493020618',
            icon: <img src={LogoOeschle} alt="LogoOeschle" />,
            state: useState(false),
            disabled: false,
            clientId: 11,
            permission: ['Integracion']
        },
        {
            id: IN_CLFIMPORT,
            title: 'Factura de Importación',
            description: <><strong>Documento: </strong>F/IMPORT INY</>,
            icon: <img src={LogoSolicitudConsumo} alt="LogoSolicitudConsumo" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion', 'IntegracionImp']
        },
        {
            id: IN_CLTRASPASO,
            title: 'Traspaso entre Bodegas',
            description: <><strong>Documento: </strong>TRASPASO INY</>,
            icon: <img src={LogoSolicitudConsumo} alt="LogoSolicitudConsumo" />,
            state: useState(false),
            disabled: false,
            clientId: 1,
            permission: ['Integracion', 'IntegracionTB']
        },
    ];

    const resetAll = () => {
        data.map(item => item.state[1](false) )
        setActive(0)
    }
    
    const { permissions: _permissions } = useSelector((state: any) => state)
    const permissions = _permissions as string[]
    const localPermission = permissions.find(p => p.startsWith('Integracion'))

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu(calculateMenu(permissions))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, exposeSubMenu])

    const reportFrame = new Map<number, JSX.Element>([
        // Selector
        [0, <Empty description={<>Selecciona en el <strong>menú izquierdo</strong><br /> un cliente para configurar el formato.</>} />],
        // CL: Falabella
        [IN_CLFALABELLA, <WarehouseInjectorMercadoLibre resetAll={resetAll} rut={'77261280'} rsocial={'FALABELLA RETAIL S.A.'} accept={'.xls'} key={'InjectorFalabella'} />],
        // CL: COMERCIAL ECCSA S.A.
        [IN_CLRIPLEY, <WarehouseInjectorMercadoLibre resetAll={resetAll} rut={'83382700'} rsocial={'COMERCIAL ECCSA S.A.'} accept={'.csv'} key={'InjectorRipleyCL'} />],
        // CL: HITES S.A.
        [IN_CLHITES, <WarehouseInjectorMercadoLibre resetAll={resetAll} rut={'81675600'} rsocial={'HITES S.A.'} accept={'.xls'} key={'InjectorHites'} />],
        // CL: EMPRESAS LA POLAR S.A.
        [IN_CLLAPOLAR, <WarehouseInjectorMercadoLibre resetAll={resetAll} rut={'96874030'} rsocial={'EMPRESAS LA POLAR S.A.'} accept={'.xls'} key={'InjectorLaPolar'} />],
        // CL: CENCOSUD RETAIL S.A.
        [IN_CLCENCO, <WarehouseInjectorMercadoLibre resetAll={resetAll} rut={'81201000'} rsocial={'CENCOSUD RETAIL S.A.'} accept={'.xls'} key={'InjectorParisCL'} />],
        // CL: Otras operaciones
        [IN_CLMAYORISTAS, <></>],
        // PE: SAGA FALABELLA S.A.
        [IN_PEFALABELLA, <WarehouseInjectorFalabella rut='20100128056' rsocial='SAGA FALABELLA S.A.' />],
        // PE: TIENDAS POR DEPARTAMENTO RIPLEY S.A.C.
        [IN_PERIPLEY, <WarehouseInjectorRipley rut='20337564373' rsocial='TIENDAS POR DEPARTAMENTO RIPLEY S.A.C.' />],
        // PE: TIENDAS PERUANAS S.A.
        [IN_PEOESCHLE, <WarehouseInjectorOeschle />],
        // CL: MERCADO LIBRE CHILE LIMITADA
        [IN_CLML, <WarehouseInjectorMercadoLibre resetAll={resetAll} key={'InjectorML'} />],
        // CL: SOLICITUDES DE CONSUMO
        [IN_CLSOLICITUDCONSUMO, <WarehouseInjectorDocuments rut='96942690' rsocial='GRETA CHILE SpA' documento='SOLICITUD INY' resetAll={resetAll} />],
        // CL: LUCATMI
        [IN_CLLUCATMI, <WarehouseInjectorInjWMSTEK rut='96942690' rsocial='GRETA CHILE SpA' documento='LUCATMI' resetAll={resetAll} />],
        // CL: F IMPORT INY
        [IN_CLFIMPORT, <WarehouseInjectorInvoice rut='96942690' rsocial='GRETA CHILE SpA' documento='F IMPORT INY' resetAll={resetAll} />],
        // CL: TRASPASO ENTRE BODEGAS
        [IN_CLTRASPASO, <WarehouseInjectorDocuments rut='96942690' rsocial='GRETA CHILE SpA' documento='TRASPASO INY' resetAll={resetAll} />],
    ])

    const back = !active ? {} : {
        onBack: () => {
            resetAll()            
        }
    }

    return (
        <div className="App">
            <div className="WarehouseInjector">
                    <PageHeader
                        className="site-page-header"
                        // onBack={() => null}
                        {...back}
                        title={!active ? "Inyectador de Movimientos" : "Volver al listado de opciones"}
                        subTitle={!active ? "Selecciona un cliente para configurar el formato específico" : ""}
                    />
                <div className="Wrapper">
                    <Row>
                        {(!isMobile || (isMobile && !active)) && <Col xs={24} md={7}>
                            <List
                                itemLayout="horizontal"
                                dataSource={data.filter(item => (item.clientId === Number(userInfo.id_client) || item.clientId === undefined) && item.permission.includes(localPermission!))}
                                renderItem={item => {
                                    const [selected, setSelected] = item.state
                                    return <List.Item className={`${selected ? 'hovered' : !active ? '' : 'wohovered'}${item.disabled ? ' disabled' : ''}`} onClick={() => { 
                                        if (!!item.disabled) return
                                        resetAll()
                                        setActive(selected ? 0 : item.id)
                                        setSelected(!selected)
                                    }}>
                                        <List.Item.Meta
                                            avatar={item.icon}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </List.Item>
                                }}
                            />
                        </Col>}
                        {(!isMobile || (isMobile && active > 0)) && <Col xs={24} md={17} className="WarehouseInjector--ReportFrame">
                            {reportFrame.get(active)}
                        </Col>}
                    </Row>
                </div>
            </div>
        </div>
    )
}