import React, { useState } from 'react'

import {
    FileExcelFilled} from '@ant-design/icons';

import {
    Steps,
    message,
    Form,
    Switch,
    Button,
    Input,
    Space,
    DatePicker,
    Row,
    Col} from 'antd';

import Dragger from 'antd/lib/upload/Dragger';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

import { createUploaderProps } from '../../Functions';
import DrawerResume from '../DrawerResume';

export default function WarehouseInjectorOeschle ({ rut = '20493020618', rsocial = 'TIENDAS PERUANAS S.A.' }) {
    const [step,] = useState(0)
    const { token } = useSelector((state: any) => state)
    const [form] = useForm()
    const props = createUploaderProps(rut, token, form, '.xls')
    const [data, setData] = useState<any[]>([])

    return <Row className="WarehouseInjector--Row">
        <DrawerResume data={data} />
        <Col span={24}>
            <Steps current={step} items={[
                { title: 'eOC', description: 'Facturación' },
                { title: 'Resumen', description: 'Validación y guardado' }
            ]} />

            {step === 0 && <div className="WarehouseInjector--Row--Step">
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <FileExcelFilled />
                            </p>
                            <p className="ant-upload-text">Selecciona archivos OC para cargar</p>
                            <p className="ant-upload-hint">
                                En este primer paso, debes cargar archivos exclusivamente de <strong>Órdenes de Compra OC</strong> desde el B2B de <strong>{rsocial}</strong>.
                            </p>
                        </Dragger>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form form={form} layout='horizontal' initialValues={{
                            validateStock: false,
                            isLimitedEdition: false,
                            validePrices: false,
                            otherWarehouse: false,
                        }} onFinish={(values) => {
                            console.log(values)
                            setData({
                                ...values.orders,
                                formConfig: {
                                    validateStock: values.validateStock,
                                    isLimitedEdition: values.isLimitedEdition,
                                    validePrices: values.validePrices,
                                    otherWarehouse: values.otherWarehouse,
                                    rut,
                                    rsocial
                                }
                            })
                        }}>
                            <Form.Item
                                name="validateStock"
                                label="¿Deseas validar el stock actual en el sistema?"
                                tooltip='Esta configuración permitirá validar el stock actual en el sistema para no incluir productos que no se encuentren en stock.'
                                valuePropName="checked"
                            >
                                <Switch disabled />
                            </Form.Item>
                            <Form.Item
                                name="isLimitedEdition"
                                label="¿Deseas procesar las órdenes como Edición Limitada?"
                                tooltip='Esta configuración permitirá procesar las órdenes como Edición Limitada, indicando al proveedor logístico que deben hacer un proceso de etiquetado especial.'
                                valuePropName="checked"
                            >
                                <Switch disabled />
                            </Form.Item>
                            <Form.Item
                                name="validePrices"
                                label="¿Deseas utilizar Listas de Precios cargadas en el sistema?"
                                tooltip="No existen listas de precios cargadas en el sistema, por lo que esta configuración no tendrá efecto."
                                valuePropName="checked"
                            >
                                <Switch disabled />
                            </Form.Item>
                            <Form.Item
                                name="otherWarehouse"
                                label="¿Deseas utilizar stock de otras bodegas?"
                                tooltip="Al seleccionar esta opción, se seleeccionarán productos de otras bodegas y se creará un documento TRASPASO previo a esta orden."
                                valuePropName="checked"
                            >
                                <Switch disabled />
                            </Form.Item>
                            <Form.Item
                                label="Fecha mínima vencimiento"
                                tooltip="Al seleccionar una fecha mínima de vencimiento, se seleccionarán productos cuyos lotes no estén vencidos según el plazo indicado."
                            >
                                <DatePicker placeholder='YYYY-MM' picker="month" />
                            </Form.Item>

                            <Form.List name="orders" rules={[
                                // { required: true, message: 'Necesitas cargar al menos un archivo eOC' }
                                {
                                    validator: async (_, orders) => {
                                        if (!orders || orders.length < 1) {
                                            message.error('Necesitas cargar al menos un archivo eOC')
                                            return Promise.reject(new Error('Necesitas cargar al menos un archivo eOC'))
                                        }
                                    }, message: 'Necesitas cargar al menos un archivo eOC'
                                }
                            ]}>
                                {(fields) => (
                                    <>
                                        {fields.map((field, i) => (
                                            <Space size={0} key={`${(form.getFieldValue('orders') ?? [])[field.key]?.path}`} style={{
                                                // display: 'flex',
                                                // marginBottom: 8,
                                                // justifyContent: 'space-between',
                                                // alignItems: 'flex-start',
                                                width: '100%',
                                                marginBottom: i === fields.length - 1 ? 48 : 0
                                            }}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'path']}
                                                    hidden
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'filename']}
                                                    hidden
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="eOC Número"
                                                    name={[field.name, 'number']}
                                                    help={`${(form.getFieldValue('orders') ?? [])[field.key]?.filename}`}
                                                    tooltip='El número de la eOC debe ser el mismo que el número de la eOD'
                                                    rules={[
                                                        { required: true, message: 'Necesitas ingresar un número de eOC' },
                                                        { pattern: /^[0-9]+$/, message: 'Para el número de la eOC, solo se permiten números' },
                                                        {
                                                            validator: (_, value) => {
                                                                const _orders = form.getFieldValue('orders') ?? [];
                                                                const _order = _orders.filter((o: any) => o.number === value)

                                                                if (_order.length > 1) {
                                                                    message.error({
                                                                        content: 'Existen eOC con el mismo número, revísalas antes de continuar.',
                                                                        key: 'order-number-duplicated',
                                                                    })
                                                                    return Promise.reject('Existen eOC con el mismo número, revísalas antes de continuar.')
                                                                }
                                                                return Promise.resolve()
                                                            }, message: 'Existen eOC con el mismo número, revísalas antes de continuar.'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Space>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                            <Form.Item className="WarehouseInjector--Submit">
                                <Button type="primary" htmlType="submit" size="large">
                                    Continuar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>}
        </Col>
    </Row>
}