import React, { useEffect, useState } from 'react'

import {
    FileExcelFilled,
    FileExcelOutlined
} from '@ant-design/icons';

import {
    Steps,
    message,
    Form,
    Button,
    Input,
    Space,
    DatePicker,
    Row,
    Col,
    Select
} from 'antd';

import Dragger from 'antd/lib/upload/Dragger';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

import { createUploaderProps } from '../../Functions';
import DrawerResume from '../DrawerResume';
import { useApi } from '../../../../Utils';
import moment from 'moment';

export default function WarehouseInjectorInvoice({ rut = '96942690', rsocial = 'GRETA CHILE SpA', documento = '', resetAll }: any) {
    const [step,] = useState(0)
    const { token } = useSelector((state: any) => state)
    const [form] = useForm()
    const props = createUploaderProps(`${rut}:${documento}`, token, form, '.xlsx', 1000)

    const { data: providersData, loading: _loading } = useApi<any>('get', 'v3/gestion/ctacteProviders/EXT')

    const [providers, setProviders] = useState<string[]>([])

    useEffect(() => {
        if (providersData?.data) {
            setProviders(providersData.data)
        }
    }, [providersData])

    const [data, setData] = useState<any[]>([])

    return <Row className="WarehouseInjector--Row">
        <DrawerResume data={data} modify={false} price={false} resetAll={resetAll} />
        <Col span={24}>
            <Steps current={step} items={[
                { title: 'Solicitud', description: 'Detalle en Excel' },
                { title: 'Resumen', description: 'Validación y guardado' }
            ]} />

            {step === 0 && <div className="WarehouseInjector--Row--Step">
                <Row gutter={16}>
                    <Col xs={24} md={9} style={{
                        gap: 16,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <FileExcelFilled />
                            </p>
                            <p className="ant-upload-text">Selecciona el archivo para inyectar</p>
                            <p className="ant-upload-hint">
                                En este primer paso, debes cargar un archivo exclusivamente de <strong>{documento}</strong> para procesar en Flexline ERP. <i>Se permite un máximo de 1.000 item's por documento.</i>
                            </p>
                        </Dragger>

                        <Button icon={<FileExcelOutlined />} target='_blank' type='ghost' block size='large' href='https://app.bodegaenlinea.cl/examples/Formato%20Carga%20F%20IMPORT%20INY.xlsx'>
                            Descargar <strong> Formato Excel </strong>
                        </Button>
                    </Col>
                    <Col xs={24} md={15}>
                        <Form form={form} layout='horizontal' className="InjDocuments__Form" initialValues={{
                            date: moment()
                        }} onFinish={(values) => {
                            setData(values.orders.map((item: any) => {
                                item.formConfig = {
                                    date: values.date,
                                    provider: values.provider,
                                    // warehouse: values.warehouse,
                                    // businessUnit: values.businessUnit,
                                    // glosa: values.glosa || ' ',
                                    // comentario: values.comentario || ' ',
                                    // limitItems: values.limitItems,
                                    rut,
                                    rsocial
                                }
                                return item
                            })
                            )
                        }}>
                            <Form.Item name="date"
                                label="Fecha de importación"
                                tooltip="Elige una fecha para cursar las facturas de importación, la paridad será calculada a partir de este día."
                                required
                                rules={[
                                    { required: true, message: 'Debes seleccionar una fecha para la importación' }
                                ]}
                            >
                                <DatePicker placeholder='YYYY-MM-DD' />
                            </Form.Item>
                            <Form.Item name="provider" label="Proveedor extranjero" required rules={[
                                { required: true, message: 'Debes seleccionar un proveedor extranjero' }
                            ]}>
                                <Select
                                    disabled={_loading}
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                        minWidth: 248
                                    }}
                                    placeholder='Selecciona un proveedor extranjero...'
                                >
                                    {providers.map(({ key, value }: any) => (
                                        <Select.Option value={key} key={value}>{key} - {value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.List name="orders" rules={[
                                // { required: true, message: 'Necesitas cargar al menos un archivo eOC' }
                                {
                                    validator: async (_, orders) => {
                                        if (!orders || orders.length < 1) {
                                            message.error('Necesitas cargar al menos un archivo de F IMPORT INY')
                                            return Promise.reject(new Error('Necesitas cargar al menos un archivo de F IMPORT INY'))
                                        }
                                    }, message: 'Necesitas cargar al menos un archivo de F IMPORT INY'
                                }
                            ]}>
                                {(fields) => (
                                    <>
                                        {fields.map((field, i) => (
                                            <Space size={0} key={`${(form.getFieldValue('orders') ?? [])[field.key]?.path}`} style={{
                                                // display: 'flex',
                                                // marginBottom: 8,
                                                // justifyContent: 'space-between',
                                                // alignItems: 'flex-start',
                                                width: '100%',
                                                marginBottom: i === fields.length - 1 ? 48 : 0
                                            }}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'path']}
                                                    hidden
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'filename']}
                                                    hidden
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    label="eOC Número"
                                                    name={[field.name, 'number']}
                                                    help={`${(form.getFieldValue('orders') ?? [])[field.key]?.filename}`}
                                                    tooltip='El número de la eOC debe ser el mismo que el número de la eOD'
                                                    rules={[
                                                        { pattern: /^[0-9]+$/, message: 'Para el número de la eOC, solo se permiten números' },
                                                        {
                                                            validator: (_, value) => {
                                                                const _orders = form.getFieldValue('orders') ?? [];
                                                                const _order = _orders.filter((o: any) => o.number === value)

                                                                if (_order.length > 1) {
                                                                    message.error({
                                                                        content: 'Existen eOC con el mismo número, revísalas antes de continuar.',
                                                                        key: 'order-number-duplicated',
                                                                    })
                                                                    return Promise.reject('Existen eOC con el mismo número, revísalas antes de continuar.')
                                                                }
                                                                return Promise.resolve()
                                                            }, message: 'Existen eOC con el mismo número, revísalas antes de continuar.'
                                                        }
                                                    ]}
                                                >
                                                    <Input type='hidden' />
                                                </Form.Item>
                                            </Space>
                                        ))}
                                    </>
                                )}
                            </Form.List>

                            <Form.Item className="WarehouseInjector--Submit">
                                <Button type="primary" htmlType="submit" size="large">
                                    Continuar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>}
        </Col>
    </Row>
}