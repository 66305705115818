import React, { useState } from 'react'

import './index.scss'

import { useHistory } from 'react-router-dom'

import { Row, Col, List, Avatar, Form, Input, Button, Checkbox, message } from 'antd'
import { UserOutlined, LockOutlined, LockFilled } from '@ant-design/icons'

import { Folders, CreditCard, LogisticaBox, CM } from '../../Assets/'

import { LoginInfoListItem } from './types'
// import { BOLRouteProps } from '../../Routes/types'

// Store
import { attemptLogin } from '../../Store/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { useMobile } from '../../Utils'

// const mapStateToProps = ({ serverUrl, token }: any) => {
//     return { serverUrl, token }
// }

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         setToken: (token: string) => dispatch(setToken(token)),
//         attemptLogin: (form: any) => dispatch(attemptLogin(form))
//     }
// }

function Login() {
    const isMobile = useMobile()
    const dispatch = useDispatch()
    const history = useHistory()

    const { token } = useSelector((state: any) => state)

    // const { isMobile, serverUrl, token, setToken } = props

    // console.log({ props })
    // console.log({  token })
//   const [token, setToken] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)

//   useEffect(() => {
//     // cookies.set('Token', token)
//   }, [token])

  const homeData: LoginInfoListItem[] = [
    {
      title: 'Aplicación Smart',
      text: 'Utiliza los dispositivos móviles (Android e iOS) asignados a tus colaboradores para una gestión ágil en movilidad.',
      avatar: Folders
    },
    {
      title: 'Integración con Sistemas',
      text: 'Conéctanos con distintas aplicaciones empresariales tales como CRM, ERP u otros a través de nuestra API e integraciones a medida.',
      avatar: CreditCard
    },
    {
      title: 'Mapa Virtual',
      text: 'Genera las mejores rutas para el picking de los artículos en tu bodega, permitiendo el trackeo de todos los movimientos..',
      avatar: LogisticaBox
    },
    {
      title: 'Perfiles y Recuentos',
      text: 'Según los permisos de tus colaboradores, genera recuentos en tiempo real y evalua indicadores de rendimiento o proyecciones.',
      avatar: CM
    }
  ]

  const onFinish = async ({ email, password, remember }: any) => {
    setLoadingButton(true)
    
      const { status, ...rest } = await dispatch<Promise<any>>(
          // @ts-ignore
        attemptLogin({
            email,
            password,
            remember
        })
      )
      if (status === 'success'){

    //   console.log(`Ha ocurrido un error. Reporte este código de error al administrador: ${btoa(err)}`)
        return history.push('/dashboard')
      } else {
          const { error } = rest
          const additionalOopsData = `${error === 'invalid.credentials' ? 'Las credenciales son inválidas.' : 'La cuenta no existe.'}`
          message.error(`Oops, ha ocurrido un error: ${additionalOopsData} Revisa los datos e intenta nuevamente.`);

        setLoadingButton(false)
      }
  };

  const onFinishFailed = (errorInfo: any) => {
    setLoadingButton(true)
    console.log('Failed:', errorInfo);
    setLoadingButton(false)
  };

  return (
    <div className="App">
      <div className="Login">
      <div className="Wrapper">
      <Row>
        <Col span={!isMobile ? 7 : 20} offset={2}>
            <div className="Software__Header" style={{ marginTop: '16px', marginBottom: '24px' }}>
              <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 822.68 838.04" overflow="inherit" style={{ float: 'left' }}> <g> <g> <path fillRule="evenodd" fill="#5ED9C9" d="M408.9,489.979c0,114.27,92.63,206.89,206.89,206.89 c114.26,0,206.89-92.62,206.89-206.89c0-114.26-92.63-206.88-206.89-206.88C501.53,283.1,408.9,375.719,408.9,489.979z M520.86,492.419c0-53.77,43.59-97.36,97.36-97.36c53.77,0,97.36,43.59,97.36,97.36s-43.59,97.36-97.36,97.36 C564.45,589.779,520.86,546.189,520.86,492.419z"/> <path fillRule="evenodd" fill="#5ED9C9" d="M334.45,353.779c57.02-25.46,73.93-72.58,52.52-134.82l-48.18-140.12 c-23-66.88-78.04-93.76-144.93-70.76L53.74,56.27c-3.41,1.17-6.73,2.44-9.93,3.78v99.57l95.52-32.85 c62.97-21.65,111.51,2.05,133.17,65.02l54.2,157.62C327.77,352.52,333.6,350.739,334.45,353.779z"/> <polygon fillRule="evenodd" fill="#5ED9C9" points="326.68,349.409 387.04,218.959 485.21,511.1 453.52,700.12 "/> </g> <path fillRule="evenodd" fill="#5ED9C9" d="M374.65,473.909c-14.07-40.58,7.42-36.21,48-50.28l209.21-121.23 c40.59-14.08,84.89,7.41,98.97,48l-23.36,325.38l-161.3,55.34c-40.59,14.07-84.89-7.42-98.97-48L374.65,473.909z M618.22,584.909 c53.77,0,97.36-43.59,97.36-97.36s-43.59-97.36-97.36-97.36c-53.77,0-97.36,43.59-97.36,97.36S564.45,584.909,618.22,584.909z"/> <circle fillRule="evenodd" fill="#46A69A" cx="75.45" cy="100.549" r="75.45"/> <circle fillRule="evenodd" fill="#255952" cx="368.085" cy="740.679" r="97.36"/> </g> </svg>
              <h1 style={{ marginLeft: '56px', marginBottom: '0px', fontSize: '20px', fontWeight: 'normal' }}>bodega<strong>en</strong>l&iacute;nea</h1>
              <h2 style={{ marginLeft: '56px', fontWeight: 'lighter', fontSize: '16px', lineHeight: '10px' }}>powered by ALNUS</h2>

              <p style={{ marginTop: '28px', lineHeight: '16px' }}>
                <br />
                Ingresa con tus credenciales para comenzar la gestión de tu bodega.
                <br /> {token}
              </p>
            </div>

            <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
              <Form.Item name="email" rules={[ { required: true, message: 'Ingresa tu correo electrónico', }, ]} >
                <Input prefix={<UserOutlined className="site-form-item-icon" />}  placeholder="Correo electrónico" autoFocus={true} />
              </Form.Item>

              <Form.Item name="password" rules={[ { required: true, message: 'Ingresa tu contraseña', }, ]} >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Contraseña"/>
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: '8px' }}>
                <Checkbox>Autorizar este equipo</Checkbox>
              </Form.Item>

              {/* <Form.Item>
                <a className="login-form-forgot" href="">
                  ¿Olvidó su contraseña?
                </a>
              </Form.Item> */}

              <Form.Item style={{ marginBottom: '0px' }}>
                <Button type="primary" htmlType="submit" loading={loadingButton} style={{ width: '100%' }} className="green">
                  Iniciar Sesión
                </Button>
              </Form.Item>

              <small style={{ fontSize: '9px', textRendering: 'optimizeLegibility' }}>
                <LockFilled /> Los datos serán transferidos mediante conexión cifrada SSL.
              </small>
            </Form>
          </Col>
          
          {!isMobile && <Col span={12} offset={1}>
            <List
              itemLayout="horizontal"
              dataSource={homeData}
              renderItem={({ avatar, title, text }) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={46} src={avatar} />}
                    title={title}
                    description={text}
                  />
                </List.Item>
              )}
            />
          </Col>}
        </Row>
        </div>
        </div>
    </div>
  )
}

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Login)

export default Login