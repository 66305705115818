import React, { useEffect, useState } from 'react'

import { 
    HomeOutlined} from '@ant-design/icons';

import { Badge, Button, Col, Descriptions, InputNumber, Row, message } from 'antd';
import { useParams } from 'react-router';
import { useApi } from '../../Utils';

import './GetOrder.scss'
import { useHistory } from 'react-router-dom';

// Store
// import { useSelector } from 'react-redux'

interface OrderRowItem {
    id: number
    paOrderId: number
    orderId: string
    internalCode: string
    sku: string
    barcode: string
    glosa: string
    productId: number
    quantity: number
    netPrice: number
    created_at: string
    updated_at?: string
}

interface OrderRow {
    id: number
    orderId: string
    date: string
    storeId: number
    extras: string
    paymentPeriod: number
    created_at: string
    updated_at?: string
    dispatchCarrierName: string
    dispatchCarrierCode: string
    lastState: string | null
    items: OrderRowItem[]
}

interface ItemUpdateOrder {
    SKU: string
    OriginalQuantity: number
    PickingQuantity: number
}


function GetOrder({ isMobile, exposeSubMenu, setMainLoading }: any) {
    const history = useHistory()
    const { orderId } = useParams<any>()
    const { data, loading, errors } = useApi<any>('get', `v3/sync/order/${orderId}`)
    const [items, setItems] = useState<ItemUpdateOrder[]>([])
    
    const [order, setOrder] = React.useState<OrderRow>()
    const [action, setAction] = useState<string>('')

    const e_UpdateState = useApi<any>('post', `v3/sync/state/${order?.orderId}/${action}`, {
        onRequest: true,
        requiredToken: true
    })

    useEffect(() => {
        if (e_UpdateState.data?.status === 'success') {
            message.success('Orden actualizada correctamente')
            history.push(`/orders`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e_UpdateState.data])

    useEffect(() => {
        if (e_UpdateState.errors.length > 0) {
            const [err] = e_UpdateState.errors
            message.error(`Error: ${err.statusMessage}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e_UpdateState.errors])

    useEffect(() => {
        if (action !== '') {
            e_UpdateState.fetch({
                items
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    useEffect(() => {
        if(order?.items) {
            setItems(order.items.map(item => {
                return {
                    SKU: item.sku,
                    OriginalQuantity: item.quantity,
                    PickingQuantity: 0
                }
            }))
        }
    }, [order])

    useEffect(() => {
        if (data?.status === 'success') {
            setOrder(data.order)
        }
    }, [data])

    useEffect(() => {
        if (errors?.length > 0) {
            for (const error of errors) {
                message.error(`Error: ${error.statusMessage}`)
            }
        }
    }, [errors])

    useEffect(() => {
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Visualización', icon: <HomeOutlined /> }
                // { key: 'orderControl', name: 'Control de Pedidos', icon: <BarcodeOutlined /> },
                // { key: 'inventory', name: 'Inventarios', icon: <UsergroupAddOutlined /> },
                // { key: 'realTime', name: 'Queue', icon: <FieldTimeOutlined /> }
            ])
        }
    }, [exposeSubMenu])

    useEffect(() => {
        setMainLoading(loading || e_UpdateState.loading)
    }, [loading, setMainLoading, e_UpdateState.loading])

    const process = () => {
        const ACTION_TOTAL = 'PickingCompleto'
        const ACITON_PARCIAL = 'PickingParcialCompletado'
        const ACTION_CERO = 'PickingParcialAnulado'

        const OriginalQuantity = items.reduce((a, b) => a + b.OriginalQuantity, 0)
        const PickingQuantity = items.reduce((a, b) => a + b.PickingQuantity, 0)

        if (PickingQuantity === OriginalQuantity) {
            setAction(ACTION_TOTAL)
        }

        if (PickingQuantity > 0 && PickingQuantity < OriginalQuantity) {
            setAction(ACITON_PARCIAL)
        }

        if (PickingQuantity === 0) {
            setAction(ACTION_CERO)
        }
    }

    return (
        <div className="App">
            <div className="GetOrders">
                <div className="Wrapper">
                    <Row gutter={32}>
                        <Col span={12}>
                            <Descriptions title="Confirmación de la Orden" bordered layout='vertical' className="Orders__POS">
                                {order?.items?.map((item, index) => (
                                    <React.Fragment key={`OrderDetail${index}`}>
                                        <Descriptions.Item label="Cód. Greta" span={1}>{item?.internalCode}</Descriptions.Item>
                                        <Descriptions.Item label="Referencia" span={1}>{item?.glosa}</Descriptions.Item>
                                        <Descriptions.Item label="Cantidad" span={1} className={index >= 0 ? `Orders__POS__Number` : ''}>
                                            <InputNumber min={0} max={item?.quantity} size='large' defaultValue={0} onChange={(value) => {
                                                const newItems = [...items]
                                                newItems[index].PickingQuantity = value || 0
                                                setItems(newItems)
                                            }} />
                                        </Descriptions.Item>
                                    </React.Fragment>
                                ))}
                            </Descriptions>
                        </Col>
                        <Col span={12}>
                            <Descriptions bordered layout='vertical'>
                                <Descriptions.Item label="Número" span={2}>{order?.orderId}</Descriptions.Item>
                                <Descriptions.Item label="Fecha">{order?.date}</Descriptions.Item>
                                <Descriptions.Item label="Periodo">{order?.paymentPeriod}</Descriptions.Item>
                                <Descriptions.Item label="Estado" span={2}>
                                    {/* TODO: Traducir lastState a los estados de BOL */}
                                    <Badge status="processing" text={order?.lastState || 'EnPreparacion'} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Transportista" span={3}>{order?.dispatchCarrierName} ({order?.dispatchCarrierCode})</Descriptions.Item>
                                {order?.extras && <Descriptions.Item label="Proceso extra adicional" span={3}>{order.extras}</Descriptions.Item>}
                            </Descriptions>
                            <Button block type='primary' size='large' onClick={process} loading={e_UpdateState.loading}>
                                Procesar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default GetOrder