import React, { useState, useEffect, useLayoutEffect } from 'react'

import {
  Button,
  Empty,
  Form,
  Space,
  Table
} from 'antd'

import { useHistory } from 'react-router-dom'
import { useApi } from '../../Utils'
// import { CategorySelector } from '../../Components/CategorySelector/CategorySelector'
import { getLastDigitOfRut } from 'rutlib'
import { useSelector } from 'react-redux'
import calculateMenu from './calculateMenu'

const { Column } = Table

export default function WarehouseCodeAlias({ exposeSubMenu, setMainLoading }: any) {
  const { data, loading, refetch } = useApi<any>(
    'get',
    'v3/sync/codeAliases/all'
  )
  
  const [refetchParams, ] = useState<any>(null)
  const [form] = Form.useForm()
  const history = useHistory()

  const { permissions } = useSelector((state: any) => state)

  useEffect(() => {
    if (!!exposeSubMenu) {
      exposeSubMenu(calculateMenu(permissions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, exposeSubMenu])

  useLayoutEffect(() => {
    setMainLoading(false)
  })


  useEffect(() => {
    if (refetchParams) {
      refetch(refetchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchParams])

  const initialValues = {
    // hasVat: true,
    hasInternalControl: null,
    // hasStockControl: true,
    enabled: true,
    search: '',
    categoryId: null
  }

  return (
      <Table
        dataSource={data?.data?.records?.data}
        loading={loading}
        pagination={{ ...data?.data?.pagination, position: ['bottomRight'] }} // 'topRight',
        onChange={(pagination, filters, sorter, extra) => {
          refetch({
            page: pagination.current,
            ...refetchParams
          })
        }}
        className='TableListAll'
        style={{
          width: '100%',
          padding: '16px'
        }}
        size='small'
        locale={{
          emptyText: (
            <Empty
              style={{ marginTop: '32px', marginBottom: '48px' }}
              description='No se han encontrado productos con los criterios de bùsqueda.'
            >
              <Button
                ghost
                type='primary'
                onClick={() => {
                  form.setFieldsValue(initialValues)
                  setTimeout(() => form.submit(), 100)
                }}
              >
                Reestablecer filtros
              </Button>
            </Empty>
          )
        }}
        footer={() => <>Total de productos {data?.data?.pagination?.total}</>}
      >
        <Column
          title='Razón Social'
          dataIndex='customerId'
          key='customerId'
          render={(value, record: any) => {
            const rzs = new Map([
              ['77398220', 'MERCADO LIBRE CHILE LIMITADA'],
              ['77261280', 'FALABELLA RETAIL S.A.'],
              ['81675600', 'HITES S.A.'],
              ['96874030', 'EMPRESAS LA POLAR S.A.'],
              ['81201000', 'CENCOSUD RETAIL S.A.'],
              ['83382700', 'COMERCIAL ECCSA S.A.'],
            ])
            // const description = new Map([
            //   [1, 'Existencia'],
            //   [2, 'Servicio']
            // ])
            return (
              <span style={{ lineHeight: '10px' }}>
                <strong>{rzs.get(record.customerId)}</strong>
                <br />
                <small>
                  ({record.customerId}-{getLastDigitOfRut(Number(record.customerId))})
                  {/* {description.get(Number(record.typeProduct))} */}
                  {/* {description.get(Number(record.typeProduct))} */}
                </small>
              </span>
            )
          }}
        />
        <Column
          title='Cód. Interno'
          dataIndex='internalCode'
          key='internalCode'
          render={(value, record: any) => {
            return <>{record.internalCode}</>
          }}
        />
        {/* <Column title="Control Stock" dataIndex="hasStockControl" key="hasStockControl" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        <Column
          title='Código Alternativo'
          dataIndex='customerCode'
          key='customerCode'
          align='right'
          render={(value: number, record: any) => {
            return (
              <>{value}</>
            )
          }}
        />
        {/* <Column title="Mìnimo" dataIndex="minStock" key="minStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="Máximo" dataIndex="maxStock" key="maxStock" align="right" render={(value: number, record: any) => {
                return <>{value <= 0 || !record.hasStockControl ? '-' : value}</>
            }} />
            <Column title="IVA" dataIndex="hasVat" key="hasVat" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        {/* <Column title="Caducidad" dataIndex="hasExpires" key="hasExpires" align="center" render={(value) => value ? <CheckCircleFilled style={{
                    color: "#1890ff"
                }} /> : <CloseCircleFilled style={{
                    color: "#00000040"
                }} />
            } /> */}
        
        {false && <Column
          title='Opciones'
          key='action'
          render={(text, record: any) => (
            <Space size='middle'>
              <Button
                onClick={() => {
                  history.push(`/masters/products/edit/${record.id}`)
                }}
                loading={toggleLoading}
                size='small'
                type='primary'
                ghost
              >
                Editar
              </Button>
              {/* <Popconfirm
                title={`¿Estás seguro de ${
                  record.enabled ? 'desactivar' : 'activar'
                } el producto ${record.name}?`}
                icon={<QuestionCircleOutlined style={{ color: record.enabled ? 'red' : 'blue' }} />}
                placement='left'
                onConfirm={() => {
                  toggleFetch({
                    productId: record.id
                  })
                }}
                onCancel={() => {
                  //
                }}
                okType={record.enabled ? 'danger' : 'primary'}
                okText='Si'
                cancelText='No'
              >
                <Button
                  size='small'
                  type={record.enabled ? undefined : 'primary'}
                  loading={toggleLoading}
                  danger={record.enabled}
                >
                  {record.enabled ? 'Desactivar' : 'Activar'}
                </Button>
              </Popconfirm> */}
            </Space>
          )}
        />}
      </Table>
    // </Space>
  )
}
